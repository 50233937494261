.SAQ_wrapper {
  margin-top: 4vh !important;
  border-top: 170px solid #e4efff !important;
  background-color: #fff !important;
  border-radius: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // min-height: 13vh !important;
  width: 80% !important;
  padding-left: 6vh !important;
  padding-right: 6vh !important;
  padding-bottom: 0vh !important;
  margin-left: auto;
  margin-right: auto;
  // max-height: 15vh;
  position: relative; // Ensure relative positioning for absolute children

  @include sm {
    padding: 4vh 3vh !important;
    border-top: 140px solid #e4efff !important;
  }

  @include md {
    margin-top: 4vh !important;
    margin-bottom: 12vh !important;
    // padding: 3vh 6vh !important;
  }

  &__time{
    padding-top: 2rem !important;
  }

  &__guide{
    color: #242222 !important;
  }
  &__Question-box {
    z-index: 7;
    position: relative;
    top: -8vh;
    width: 60%;
    padding: 3vh 8vh;
    background-color: #fff;
    // margin-top: 5vh !important;
    box-shadow: $smooth-shadow;
    border-radius: 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include sm {
      padding: 6vh 3vh;
      width: 77% !important;
      margin-top: 0vh !important;
    }

    @include md {
      padding: 5vh 3vh;
      margin-top: 7vh !important;
      width: 75%;
    }

    &--number {
      position: relative;
      top: -6vh;
      border-radius: 30px;
      background-color: $secondary-app-color !important;

      &-text {
        padding: 1.2vh 5.5vh !important;
        font-family: $Gilroy-bold !important;
        font-size: 1.05rem !important;
        color: #fff !important;

        @include sm {
          padding: 1.2vh 6.5vh !important;
          font-size: 1rem !important;
        }
      }
    }

    &--question {
      font-family: $default-font-family !important;
      font-size: 1.2rem !important;
      color: $secondary-app-color !important;

      @include sm {
        font-size: 1rem !important;
      }

      @include md {
        font-size: 1.2rem !important;
      }
    }
  }

  &__options-wrapper {
    margin-top: 25vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10rem;

    @include sm {
      margin-top: 16vh !important;
    }

    &--option-button {
      white-space: normal !important;
      border: 1px solid rgba(43, 61, 79, 0.26) !important;
      border-radius: 15px !important;
      font-family: $default-font-family !important;
      font-size: 1.1rem !important;
      color: $secondary-app-color !important;
      text-transform: capitalize !important;

      @include sm {
        font-size: 0.9rem !important;
      }

      @include md {
        padding: 1vh 11.5vh !important;
      }

      &--active {
        white-space: normal !important;
        border-radius: 15px !important;
        font-family: $default-font-family !important;
        font-size: 1.1rem !important;
        color: $secondary-app-color !important;
        background-color: #eaffff !important;
        border: 2px solid $primary-color !important;
        text-transform: capitalize !important;

        @include sm {
          font-size: 0.9rem !important;
        }

        @include md {
          padding: 1vh 11.5vh !important;
        }
      }
    }

    &--option-icon-g {
      white-space: nowrap !important;
      font-size: 5.1rem !important;
      border-radius: 50% !important; // Make the border circular
      color: #242222 !important; // Set the icon color to white
      border-color: $secondary-app-color !important;
      width: 6rem !important; // Adjust as per the icon size to ensure the circle shape
      height: 6rem !important; // Adjust to keep the circle shape

      @include sm {
        font-size: 0.9rem !important;
        width: 3rem;
        height: 3rem;
      }

      @include md {
        padding: 1vh 11.5vh !important;
      }

      &--active {
        font-size: 5.1rem !important;
        border-radius: 50% !important; // Make the border circular
        color: #fff !important; // Set the icon color to white
        background-color: $primary-color !important; // Set the background color to black
        width: 6rem !important; // Adjust as per the icon size to ensure the circle shape
        height: 6rem !important; // Adjust to keep the circle shape

        @include sm {
          font-size: 0.9rem !important;
        }

        @include md {
          padding: 1vh 11.5vh !important;
        }
      }
    }

    &--option-icon-r {
      white-space: nowrap !important;
      font-size: 5.1rem !important;
      border-radius: 50% !important; // Make the border circular
      color: #242222 !important; // Set the icon color to white
      border-color: red !important;
      width: 6rem !important; // Adjust as per the icon size to ensure the circle shape
      height: 6rem !important; // Adjust to keep the circle shape

      @include sm {
        font-size: 0.9rem !important;
        width: 3rem;
        height: 3rem;
      }

      @include md {
        padding: 1vh 11.5vh !important;
      }

      &--active {
        font-size: 5.1rem !important;
        border-radius: 50% !important; // Make the border circular
        color: #fff !important; // Set the icon color to white
        background-color: red !important; // Set the background color to black
        width: 6rem !important; // Adjust as per the icon size to ensure the circle shape
        height: 6rem !important; // Adjust to keep the circle shape

        @include sm {
          font-size: 0.9rem !important;
        }

        @include md {
          padding: 1vh 11.5vh !important;
        }
      }
    }
  }

  &__answer-wrapper {
    position: relative;
    top: -24vh;
    width: 80%;
    margin-bottom: -110px !important;

    @include sm {
      width: 87%;
    }

    @include md {
      width: 100%;
    }
  }

  &-navigate {
    margin-top: 0vh !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff !important;
    padding: 5vh 0.5vh !important;
    
   

    @include sm {
      padding: 0vh 0vh !important;
    }

    @include md {
      padding: 0vh 0vh !important;
    }

    &#no-bg {
      background-color: transparent !important;

      @include sm {
        margin-left: 0vh !important;
        margin-bottom: 3vh !important;
      }

      @include md {
        margin-left: 0vh !important;
      }
    }

    &__button {
      font-size: 0.85rem !important;
      color: #3b5269 !important;
      padding: 0.7vh 8vh !important;
      font-family: $Gilroy-bold !important;
      border-radius: 30px !important;
      border-color: #2b3d4f !important;
      text-transform: capitalize !important;
      position: relative;
      margin-bottom: 5px !important;

      @include sm {
        padding: 0.7vh 3vh !important;
        font-size: 0.7rem !important;
        margin-top: 15px !important;
      }

      @include md {
        padding: 0.5vh 6vh !important;
        font-size: 0.7rem !important;
        margin-bottom: 3vh !important;
      }

      &#next {
        margin-right: 2vh !important;

        @include sm {
          margin-right: 0vh !important;
        }
      }

      &#white-bg {
        background-color: #fff !important;
      }

      &.active::after {
        opacity: 1;
      }
    }

    &__title {
      font-size: 1.3rem !important;
      color: #242222 !important;
      font-family: $default-font-family !important;

      @include sm {
        font-size: 1rem !important;
      }

      @include md {
        font-size: 0.95rem !important;
      }
    }

    &__progress {
      width: 105%;
      height: 7px !important;
      border-radius: 0px !important;
      background-color: #e91515 !important;

      @include sm {
        width: 105% !important;
        margin-right: 6vh !important;
        border-radius: 0px !important;
      }

      @include md {
        width: 106% !important;
        margin-right: 0vh !important;
      }

      .css-5xe99f-MuiLinearProgress-bar1 {
        background-color: $secondary-app-color !important;
      }
    }
  }

  &__progress {
    z-index: 10 !important;
    position: absolute !important;
    bottom: 0; // Align to the bottom of the card
    left: 0; // Align to the left of the card
    height: 8px !important;
    width: 100%;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: $secondary-app-color !important;
    color: $primary-color !important;

    @include sm {
      // Custom responsive styles if needed
    }

    @include md {
      // Custom responsive styles if needed
    }

    .css-5xe99f-MuiLinearProgress-bar1 {
      background-color: $primary-color !important;
    }
  }

  &__emoji-button {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    background-color: transparent;
    border: 2px solid transparent;
    /* Base border color */
    border-radius: 50%;
    /* Make button circular by default */
    transition: transform 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    padding: 10px;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      /* Slight scaling on hover */
      border-color: #ccc;
      /* Add a subtle border color on hover */
      background-color: rgba(0, 0, 0, 0.05);
      /* Light background change on hover */
    }

    &__selected {
      border-color: green;
      /* Green border for selected emoji */
      background-color: #4caf50;
      /* Light green background when selected */
      color: white;
      /* Make emoji white when selected */
      transform: scale(1.1);
      /* Slightly scale when selected */
      border-radius: 0;
      /* Make the box square */
      padding: 15px;
      /* Add padding to make the box bigger */
      box-shadow: 0 0 5px rgba(0, 128, 0, 0.5);
      /* Optional shadow to enhance the selected effect */
    }

    &__size {
      font-size: 3rem !important;
      line-height: 1 !important;
      text-align: center !important;
    }
  }
}

.AFQ_wrapper {
  margin-top: 4vh !important;
  background-color: #fff !important;
  border-radius: 30px !important;
  width: 80% !important;
  padding-left: 6vh !important;
  padding-right: 6vh !important;
  padding-bottom: 6vh !important;
  margin-left: auto;
  margin-right: auto;

  @include sm {
    padding: 4vh 3vh !important;
    // border-top: 140px solid #e4efff !important;
  }

  @include md {
    margin-top: 4vh !important;
    margin-bottom: 12vh !important;
  }

  &__time{
    padding-top: 2rem !important;
  }

  &__options-wrapper {
    margin-top: 25vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    @include sm {
      margin-top: 16vh !important;
    }
  }

  &__answer-wrapper {
    width: 100% !important;
    margin-top: 30px !important;
    margin-bottom:1.5px;

    /* Ensures the wrapper spans full width */
    @include sm {
      width: 87%;
    }

    @include md {
      width: 100%;
    }
  }

  &__long-question-wrapper {
    padding: 4vh 0vh !important;
    font-size: 18px !important;
    font-family: $Gilroy-light !important;
    color: black;
    line-height: 1.9;
    text-align: unset;
    word-break: normal !important;
    overflow-wrap: break-word !important; // Handles long unbreakable words like URLs

    white-space: normal !important;

    p {
      text-align: unset;
    }
  }

  &__Question-box-v1 {
    top: 4vh;
    width: 100%;
    padding: 3vh 0vh;
    background-color: #e4efff;
    // margin-top: 5vh !important;
    box-shadow: $smooth-shadow;
    border-radius: 15px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;

    @include sm {
      padding: 6vh 0vh;
      margin-top: 0vh !important;
    }

    @include md {
      padding: 5vh 0vh;
      margin-top: 7vh !important;
    }

    &--number {
      position: relative;
      top: -6vh;
      border-radius: 30px;
      background-color: $secondary-app-color !important;

      &-text {
        padding: 1.2vh 5.5vh !important;
        font-family: $Gilroy-bold !important;
        font-size: 1.05rem !important;
        color: #fff !important;

        @include sm {
          padding: 1.2vh 6.5vh !important;
          font-size: 1rem !important;
        }
      }
    }

    &--question {
      font-family: $default-font-family !important;
      font-size: 1.2rem !important;
      color: $secondary-app-color !important;

      @include sm {
        font-size: 1rem !important;
      }

      @include md {
        font-size: 1.2rem !important;
      }
    }
  }
}


.IC_wrapper {
  margin-top: 4vh !important;
  border-radius: 30px !important;
  width: 80% !important;
  padding-left: 6vh !important;
  padding-right: 6vh !important;
  padding-bottom: 6vh !important;
  margin-left: auto;
  margin-right: auto;

  @include sm {
    padding: 4vh 3vh !important;
    // border-top: 140px solid #e4efff !important;
  }

  @include md {
    margin-top: 4vh !important;
    margin-bottom: 12vh !important;
  }

  &__image-container {
    // Styling for the image container
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    
    // Ensures the image container is responsive
    width: 100%;
    height: 350px;
    @media (max-width: 768px) {
      height: 300px;
    }

    @media (max-width: 480px) {
      height: 200px;
    }
  }

  &__image {
    // Image styling
    max-width: 100%;
    height: 100%;
    
    // Make sure image adjusts to various screen sizes
    @media (max-width: 768px) {
      max-width: 100%;
    }

    @media (max-width: 480px) {
      max-width: 100%;
    }
  }
}


.css-ttgsjq-MuiSlider-track{
  background-color: #242222 !important;
  border: 1px solid #242222 !important;
}
.css-188mx6n-MuiSlider-root{
  color: #2b3d4f !important;
}
.css-ltlhnc-MuiSlider-root{
  color: #3b5269 !important;
}