.course-details-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: none !important;
  &__description {
    margin-bottom: 2vh !important;
    &--title {
      font-size: 1.8rem !important;
      font-family: $Gilroy-semi-bold !important;
      color: $secondary-app-color !important;
      margin-bottom: 1vh !important;
      @include sm {
        font-size: 1.7rem !important;
      }
      @include md {
        font-size: 1.7rem !important;
      }
    }
    &--caption {
      font-size: 0.95rem !important;
      font-family: $Gilroy-light !important;
      color: #000 !important;
      @include sm {
        font-size: 0.9rem !important;
      }
      @include md {
        font-size: 0.9rem !important;
      }
    }
  }
  &__heading {
    margin-top: 4vh !important;
    margin-bottom: 2vh !important;
    font-size: 1.3rem !important;
    font-family: $default-font-family !important;
    color: #000 !important;
    @include sm {
      font-size: 1.25rem !important;
    }
    @include md {
      margin-top: 3vh !important;
      margin-bottom: 1vh !important;

      font-size: 1.25rem !important;
    }
  }

  &__cards-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    width: 100%;
    margin-top: 2vh !important;
    margin-bottom: 4vh !important;
    @include sm {
      flex-direction: column;
      gap: 1rem;
    }
    @include md {
      gap: 1rem;
    }
    &--card {
      width: 30%;
      background-color: #fff !important;
      padding: 3vh 5vh !important;
      border-radius: 15px;
      @include sm {
        width: 50%;
      }
      @include md {
        padding: 3vh 3.5vh !important;
      }
      &--title {
        font-size: 0.95rem !important;
        font-family: $Gilroy-light !important;
        color: #000 !important;
        @include sm {
          font-size: 1.05rem !important;
        }
        @include md {
          font-size: 1rem !important;
        }
      }
      &-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1vh;
        gap: 0.5rem;
        &--icon {
          width: 13%;
          &#duration {
            width: 12%;
            @include md {
              width: 13.5%;
            }
          }
          &#progress {
            width: 12%;
            @include md {
              width: 13.5%;
            }
          }
          @include sm {
            width: 12.5%;
          }
          @include md {
            width: 13.5%;
          }
        }
        &--caption {
          font-size: 1.2rem !important;
          font-family: $default-font-family !important;
          color: #000 !important;
          @include sm {
            font-size: 1.25rem !important;
          }
          @include md {
            font-size: 1.15rem !important;
          }
        }
        
      }
    }
  }
}
