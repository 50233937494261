.pdf-component {
  object-fit: contain;
  // overflow: hidden;
  width: 80%;
  // height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;
  border-radius: 30px !important;
  padding: 5vh 6vh !important;
  margin-top: 4vh !important;
  margin-left: auto !important;
  margin-right: auto !important;
  @include sm {
    margin-left: 2vh !important;
    margin-right: 2vh !important;
  }
  @include md {
    margin-left: 3vh !important;
    margin-right: 3vh !important;
  }
}

.react-pdf__Page__canvas {
  margin: auto;
  width: 80% !important;
  height: 100% !important;
}

.page-controls {
  width: 15%;
  position: absolute;

  bottom: -12%;
  left: 50%;
 
 
  transform: translate(-50%);
  transition: opacity ease-in-out 0.2s;
 
  border-radius: 8px !important;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 0.5vh 1vh;
  //shift this down a bit
 
 
  @include sm {
    width: 25%;
    bottom: -8%;
  }
  @include md {
    width: 22%;
    bottom: -20%;
  }
}
.page-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-controls button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-controls button {
  width: 30%;
  height: 25%;
  background: white;
  border: 0;
  font-size: 2rem !important;
  font-family: $Gilroy-light !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
}
.page-controls button:active {
  background: #ddd;
}
.page-controls span {
  font: inherit;
  font-family: $Gilroy-light !important;
  white-space: nowrap !important;
  font-size: 0.85rem !important;
  padding: 0.5vh 1vh !important;
}
