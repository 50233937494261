.app-layout-navbar {
  background-color: black;
}
.navbar-logo {
  width: 100%;
  max-width: 72px;
  aspect-ratio: 72 / 38;
  // margin-right: 2;
  object-fit: contain;
  @include sm {
    width: 75%;
  }
  @include md {
    width: 85%;
  }
}

.appbar-navbar {
  position: static;
  background-color: #fff !important;
  box-shadow: none !important;
  @include xs {
    box-shadow: none !important;
  }
  @include sm {
    box-shadow: none !important;
  }
  @include md {
    box-shadow: none !important;
  }
  @include lg {
    box-shadow: none !important;
  }
  @include xl {
    box-shadow: none !important;
  }
}

.appbar-toolbar-navbar {
  background-color: #fff;
  justify-content: space-between;
}
.navbar-button {
  background-color: #ffffff;
  color: #3b5269 !important;
  text-transform: none;
  padding: 6px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  @include sm {
    font-size: 0.5rem !important;
  }
  @include md {
    font-size: 0.7rem !important;
  }

  &:hover {
    background-color: #f0f0f0;

    &.active {
      background-color: #f0f0f0;
    }
  }
}

//smartphone responsive

//------------------------sm device

.dropdown__button {
  font-size: 1rem !important;
  @include md {
    font-size: 0.9rem !important;
  }
}

.courses {
  text-transform: none !important;
  font-size: 1rem !important;
  color: #000 !important;
  @include sm {
    display: none !important;
  }
  @include md {
    font-size: 0.9rem !important;
  }
}
.dashboard {
  text-transform: none !important;
  font-size: 1rem !important;
  color: #000 !important;
  @include xs {
    display: none !important;
  }
  @include sm {
    display: none !important;
  }
  @include md {
    font-size: 0.9rem !important;
  }
}
