.course-info-wrapper {
  &__title {
    font-size: 1rem !important;
    font-family: $default-font-family !important;
    margin-bottom: 0.5rem !important;
    @include sm {
      font-size: 0.8rem !important;
    }
    @include md {
      font-size: 0.9rem !important;
    }
  }

  &__details {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    @include sm {
      margin-top: 0.5vh !important;
      gap: 1rem;
    }
    @include md {
      margin-top: 0.6vh !important;
      gap: 1rem;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-icon {
      width: 10%;
      margin-right: 0.25rem;
    }
    #star {
      width: 30%;
      @include sm {
        margin-right: 0.4rem;
        width: 54% !important;
      }
      @include md {
        width: 51% !important;
        margin-right: 0.4rem;
      }
    }
    #barchart {
      width: 16%;
      @include sm {
        margin-right: 0.4rem;
        width: 19% !important;
      }
      @include md {
        width: 17% !important;
        margin-right: 0.4rem;
      }
    }
    #clock {
      width: 22%;
      @include sm {
        margin-right: 0.4rem;
        width: 34% !important;
      }
      @include md {
        width: 33% !important;
        margin-right: 0.4rem;
      }
    }

    &-text {
      margin-left: 0.1vh !important;
      font-family: $Gilroy-light !important;
      font-size: 0.85rem !important;
      color: #555;
      @include sm {
        margin-right: 0.4rem !important;
        margin-left: 0vh !important;
        font-size: 0.7rem !important;
        white-space: nowrap;
      }
      @include md {
        margin-right: 0.55rem !important;
        margin-left: 0vh !important;
        font-size: 0.8rem !important;
        white-space: nowrap;
      }
    }
  }
}
