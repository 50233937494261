// Variables for consistent colors
$navbar-bg: #fff;
$dark-bg: #2c3e50;
$button-bg: #1abc9c;
$text-color: #fff;
$input-bg: #fff;

// Main Container for the Certificate Verification Section
.certificate-container {
  display: flex;
  padding: 20vh 4vh !important;
  text-align: center;

  .certificate-title {
    font-size: 2rem;
    margin-bottom: 10vh !important;
    font-family: $Gilroy-semi-bold !important;
    line-height: 20px;
  }

  .verification-box {
    background-color: $dark-bg;
    padding: 40px;
    border-radius: 30px;
    color: $text-color;
    padding-top: 10vh;

    // Flex layout for the input fields
    .input-group {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      // To handle spacing and layout for 11 input fields
      .input-field {
        width: 40px;
        text-align: center;
        border-radius: 4px;
        border-bottom: 2px solid #ccc;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        text-transform: uppercase !important;
      }
    }

    // Button style for verification
    .verify-button {
      margin-top: 4vh;
      background-color: $button-bg;
      color: $text-color;
      padding: 10px 50px;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background-color: darken($button-bg, 10%);
      }
    }
  }
}

// Media Queries for Responsiveness

// Tablet devices (768px to 1024px)
@media (max-width: 1024px) {
  .certificate-container {
    padding: 15vh 2vh !important;

    .certificate-title {
      font-size: 1.8rem;
      margin-bottom: 8vh !important;
    }

    .verification-box {
      width: 90%; // Increase width to fit smaller screen sizes
      padding: 30px;

      .input-group {
        justify-content: space-around; // Adjust spacing
        .input-field {
          width: 35px; // Reduce input field size for tablets
        }
      }

      .verify-button {
        padding: 8px 40px; // Adjust button size
      }
    }
  }
}

// Mobile devices (max-width: 767px)
@media (max-width: 767px) {
  .certificate-container {
    padding: 10vh 1vh !important;

    .certificate-title {
      font-size: 1.5rem;
      margin-bottom: 5vh !important;
    }

    .verification-box {
      width: 100%; // Full width for mobile devices
      padding: 20px;

      .input-group {
        justify-content: space-between;
        .input-field {
          width: 30px; // Reduce input field size for mobile
        }
      }

      .verify-button {
        padding: 8px 30px; // Further reduce button size for mobile
      }
    }
  }
}

// Container for the entire certificate page
.certificate-container-card {
    padding: 2rem;
    background-color: #f2f7f9;
    min-height: 100vh;
  
    @media (max-width: 600px) {
      padding: 1rem;
    }
  }
  
  // Header title for the certificate section
  .header-title {
    font-size: 2rem;
    font-family: $Gilroy-semi-bold!important;
    line-height: 20px;
  
    @media (max-width: 960px) {
      font-size: 1.5rem;
      line-height: 1.2;
    }
  
    @media (max-width: 600px) {
      font-size: 1.2rem;
      line-height: 1.2;
    }
  }
  
  .certificate-preview-card {
    padding-right: 8vh;
  
    @media (max-width: 960px) {
      padding-right: 5vh;
    }
  
    @media (max-width: 600px) {
      padding-right: 2vh;
    }
  
    // Certificate preview section
    .certificate-preview{
      height: 440px;
      background-color: #7d8b93;
      border-radius: 30px !important;
      Document{
          border-radius: 30px !important;
          height: 100% !important;
          width: 100% !important;

      }
  
      @media (max-width: 960px) {
        height: 280px;
      }
  
      @media (max-width: 600px) {
        height: 220px;
        border-radius: 15px;
      }
    }
  
    // Action buttons section
    .action-buttons {
      margin-top: 6vh;
  
      @media (max-width: 960px) {
        margin-top: 4vh;
      }
  
      @media (max-width: 600px) {
        margin-top: 2vh;
      }
  
      .singleButton {
        margin-right: 2vh;
  
        @media (max-width: 600px) {
          margin-right: 1vh;
        }
      }
    }
  
    // Description section
    .description {
      margin: 6vh 0;
  
      @media (max-width: 960px) {
        margin: 4vh 0;
      }
  
      @media (max-width: 600px) {
        margin: 2vh 0;
      }
  
      .dummy-text {
        font-size: 1rem;
        line-height: 1.5;
        font-family: $Gilroy-light !important;
  
        @media (max-width: 960px) {
          font-size: 0.9rem;
        }
  
        @media (max-width: 600px) {
          font-size: 0.8rem;
        }
      }
    }
  
    // Section title for recipient and course
    .section-title {
      font-size: 2rem;
      font-family: $Gilroy-semi-bold !important;
      line-height: 20px;
      font-weight: bold;
  
      @media (max-width: 960px) {
        font-size: 1.5rem;
      }
  
      @media (max-width: 600px) {
        font-size: 1.2rem;
      }
    }
  
    // Recipient section
    .recipient-avatar {
      background-color: #2c3e50;
  
      @media (max-width: 600px) {
        width: 40px;
        height: 40px;
      }
    }
  
    .recipient-name {
      font-size: 1rem;
  
      @media (max-width: 600px) {
        font-size: 0.9rem;
      }
    }
  }
  


