.course-view-component-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  width: 100%;
  height: 92%;
  padding: 6vh;
  gap: 3rem;
  @include md {
    height: 81%;
  }
}
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #333;
}
