.breadcrumb {
  &__parent-link {
    font-family: $Gilroy-light !important;
    font-size: 0.9rem !important;
    color: #000 !important;
    @include sm {
      font-size: 0.7rem !important;
    }
    @include md {
      font-size: 0.85rem !important;
    }
  }
  &__child-link {
    font-family: $Gilroy-light !important;
    font-size: 0.9rem !important;
    color: #000 !important;
    @include sm {
      font-size: 0.7rem !important;
    }
    @include md {
      font-size: 0.85rem !important;
    }
  }
  &__grand-child-link {
    font-family: $Gilroy-light !important;
    font-size: 0.9rem !important;
    color: #000 !important;
    @include sm {
      font-size: 0.7rem !important;
    }
    @include md {
      font-size: 0.85rem !important;
    }
  }
}

//seperator
.css-1wuw8dw-MuiBreadcrumbs-separator {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
