//------------------------- app layout page
.app-layout__container {
  // overflow-x: hidden;
  // overflow-y: hidden;
  background: #f5faff;

  @include sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  @include md {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  @include lg {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  @include xs {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  @include xl {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

//smartphone responsive

//----------------for sm device

.page-body {
  min-height: 88vh;
  // height: 100vh !important;
  padding-left: 4vh !important;
  padding-right: 4vh !important;
  padding-top: 3vh !important;
  padding-bottom: 3vh !important;
  @include xs {
    margin-top: 8vh !important;
  }
  @include sm {
    margin-top: 8vh !important;
  }
  @include md {
    margin-top: 6vh !important;
  }
  @include lg {
    margin-top: 8vh !important;
  }
  @include xl {
    margin-top: 8vh !important;
  }
}

.session-expiry-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  background-color: #f5faff;
  padding: 4vh !important;
  border-radius: 30px !important;
  box-shadow: none !important;
  @include sm {
    width: 70%;
  }
  @include md {
    width: 50%;
  }

  // &#datepicker{
  //   width: 25%;
  // }
  &__datepicker-wrapper {
    margin-top: 1.5vh !important;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;

    &--datepicker {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
    }
    &--button {
      border-color: #2b3d4f !important;
      border-radius: 10px !important;
      padding: 0.5vh 4vh !important;
      font-size: 1rem !important;
      font-family: $Gilroy-regular !important;
      color: #f5faff !important;
      background-color: #2b3d4f !important;
      box-shadow: none !important;
      text-transform: capitalize !important;
      @include sm {
        font-size: 0.95rem !important;
      }
      @include md {
        font-size: 0.95rem !important;
      }
    }
  }
  &__heading {
    font-family: $Gilroy-bold !important;
    font-size: 1.2rem !important;
    color: #2b3d4f !important;
    @include sm {
      font-size: 1rem !important;
    }
    @include md {
      font-size: 1.1rem !important;
    }
  }
  &__description {
    font-family: $Gilroy-bold !important;
    font-size: 0.9rem !important;
    color: #2b3d4f !important;
    @include sm {
      font-size: 0.85rem !important;
    }
    @include md {
      font-size: 0.85rem !important;
    }
  }
}
