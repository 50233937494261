.question-wrapper {
  margin-top: 4vh !important;

  &__image {
    width: 100%;
    margin-bottom: 10vh;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3vh 4vh 10vh 4vh !important;
    margin-top: 3vh !important;
    &-question {
      border-radius: 30px !important;
      background-color: #fff !important;
      padding: 3vh 5vh !important;
      width: 90%;
      margin-right: 4%;
      margin-left: 4%;
      &-text {
        color: #646e83 !important;
        font-family: $default-font-family !important;
        font-size: 1.4rem !important;
      }
    }
    &-input {
      width: 100%;
      &-field {
        border-color: rgba(43, 61, 79, 0.3) !important;
        margin-top: 5vh !important;
        width: 100% !important;
      }
    }
  }
}

.quiz-start-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 2rem;
  padding: 2rem;
  border-radius: 20px;
  text-align: center;
  height: 400px;
  width: 85%; // Ensures it takes up the available width
}

.html_structure_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 2rem;

  &__heart-container {
    position: relative;
    width: 550px; /* Increased heart size */
    height: 550px;
  }

  &__svg_heart {
    width: 100%;
    height: 100%;
  }
  &__editable-box {
    position: absolute;
    width: 30%; /* Further reduced size to avoid overlap */
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: black;
    background-color: rgba(255, 255, 255, 0.2); /* Light red background */
    text-align: center;
    outline: none;
  }
  &__editable-box:focus {
    border: none;
  }

  &__top-left {
    top: 10%; /* Adjusted to better fit inside the heart */
    left: 15%;
  }

  &__top-right {
    top: 10%; /* Symmetrically adjusted */
    right: 15%;
  }

  &__bottom-left {
    bottom: 15%; /* Adjusted to align within the heart curve */
    left: 20%;
  }

  &__bottom-right {
    bottom: 15%; /* Symmetrically adjusted */
    right: 20%;
  }
  &__word-list {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }

  &__word-list button {
    padding: 10px;
    cursor: pointer;
  }
  &__html_table {
    width: 100%;
    border-collapse: collapse;
    &-th {
      background-color: #f2f2f2;
      border: 1px solid black;
    }
    &-td {
      border: 1px solid black;
      text-align: left;
      padding: 10px;
      outline: none;
    }
    &-input {
      width: 100%;
      padding: 4px;
      box-sizing: border-box;
      border: none;
      outline: none;
    }
    &-input[type="text"]:focus {
      outline: none;
      border: none;
    }
    &_selected {
      background-color: #87ceeb;
    }
  }
  &__tic-tac-toe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 360px;
    &__row {
      display: flex;
    }
    &__cell {
      border: 1px solid #333;
      width: 150px;
      height: 150px;
      text-align: center;
      font-size: 16px;
      outline: none;
      align-items: center;
      justify-content: center;
      display: flex;
    }
    &--cell:focus {
      border-color: #007bff;
      background-color: #e9ecef;
    }
  }

}
