/* Success Type */
.snackbar-wrapper-success {
  border-left: 6px solid #defae4;
  padding: 2.5vh 3vh !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 1.2rem;
  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #defae4;
    padding: 1.3vh;
    border-radius: 50%;
    &--icon {
      color: #228d2f;
      font-size: 1.6rem;
    }
  }
  &__content-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 0.2rem;
    &--title {
      font-size: 1.1rem !important;
      font-family: $Gilroy-semi-bold !important;
      color: #228d2f !important;
      text-transform: capitalize !important;
      text-decoration: none !important;
    }
    &--caption {
      font-size: 0.9rem !important;
      font-family: $Gilroy-regular !important;
      color: #6e7e95 !important;
      text-transform: capitalize !important;
      text-decoration: none !important;
    }
  }
  &__close-icon {
    margin-left: 1vh;
    font-size: 1.5rem;
    opacity: 0.5;
  }
}

.snackbar-wrapper-info {
  border-left: 6px solid #e6f0ff;
  padding: 2.5vh 3vh !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 1.2rem;
  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6f0ff;
    padding: 1.3vh;
    border-radius: 50%;
    &--icon {
      color: #1c6efb;
      font-size: 1.6rem;
    }
  }
  &__content-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 0.2rem;
    &--title {
      font-size: 1.1rem !important;
      font-family: $Gilroy-semi-bold !important;
      color: #1c6efb !important;
      text-transform: capitalize !important;
      text-decoration: none !important;
    }
    &--caption {
      font-size: 0.9rem !important;
      font-family: $Gilroy-regular !important;
      color: #6e7e95 !important;
      text-transform: capitalize !important;
      text-decoration: none !important;
    }
  }
  &__close-icon {
    margin-left: 1vh;
    font-size: 1.5rem;
    opacity: 0.5;
  }
}

.snackbar-wrapper-warning {
  border-left: 6px solid #fdf0e1;
  padding: 2.5vh 3vh !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 1.2rem;
  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fdf0e1;
    padding: 1.3vh;
    border-radius: 50%;
    &--icon {
      color: #fd8e31;
      font-size: 1.6rem;
    }
  }
  &__content-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 0.2rem;
    &--title {
      font-size: 1.1rem !important;
      font-family: $Gilroy-semi-bold !important;
      color: #fd8e31 !important;
      text-transform: capitalize !important;
      text-decoration: none !important;
    }
    &--caption {
      font-size: 0.9rem !important;
      font-family: $Gilroy-regular !important;
      color: #6e7e95 !important;
      text-transform: capitalize !important;
      text-decoration: none !important;
    }
  }
  &__close-icon {
    margin-left: 1vh;
    font-size: 1.5rem;
    opacity: 0.5;
  }
}

.snackbar-wrapper-error {
  border-left: 6px solid #ffecec;
  padding: 2.5vh 3vh !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 1.2rem;
  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffecec;
    padding: 1.3vh;
    border-radius: 50%;
    &--icon {
      color: #c04242;
      font-size: 1.6rem;
    }
  }
  &__content-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 0.2rem;
    &--title {
      font-size: 1.1rem !important;
      font-family: $Gilroy-semi-bold !important;
      color: #c04242 !important;
      text-transform: capitalize !important;
      text-decoration: none !important;
    }
    &--caption {
      font-size: 0.9rem !important;
      font-family: $Gilroy-regular !important;
      color: #6e7e95 !important;
      text-transform: capitalize !important;
      text-decoration: none !important;
    }
  }
  &__close-icon {
    margin-left: 1vh;
    font-size: 1.5rem;
    opacity: 0.5;
  }
}
