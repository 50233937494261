.setting-up-account-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 2px solid #c2c2c2 !important;
  // border-bottom: 2px solid #c2c2c2 !important;
  margin-top: 4vh !important;
  margin-bottom: 3vh !important;
  padding: 3vh 0vh !important;
  @include sm {
    width: 100%;
  }
  @include md {
    width: 50%;
  }
  &#account-security {
    border-bottom: none !important;
  }

  &__title {
    margin-top: 4vh !important;
    margin-bottom: 3vh !important;
    font-size: 1rem !important;
    font-family: $Gilroy-semi-bold !important;
    color: #000 !important;
    @include sm {
      font-size: 0.9rem !important;
    }
    @include md {
      font-size: 0.95rem !important;
    }
  }
  &__details-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.5vh;
    justify-content: space-between;

    &--text {
      cursor: pointer;
      font-size: 0.9rem !important;
      font-family: $default-font-family !important;
      color: #000 !important;
      @include sm {
        font-size: 0.75rem !important;
      }
      @include md {
        font-size: 0.83rem !important;
      }
    }
    &--icon {
      width: 4%;
      transform: rotate(-90deg);
      @include sm {
        width: 6%;
      }
      @include md {
        width: 5.5%;
      }
    }
  }
  &__link {
    margin-top: 1vh !important;
    text-decoration: underline !important;
    margin-bottom: 3vh !important;
    color: #209292 !important;
    font-family: $Gilroy-light !important;
    font-size: 0.9rem !important;
    text-decoration-color: #209292 !important;
    @include sm {
      font-size: 0.8rem !important;
    }
    @include md {
      font-size: 0.85rem !important;
    }
  }
}

//mts-security

.mts-security {
  width: 50%;
  border-top: 2px solid #c2c2c2 !important ;
  padding-top: 4vh !important;
  @include sm {
    width: 100%;
  }
}
