.contact-us-wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: #fff;
  border-radius: 12px;
  width: 50%;
  margin-top: 9vh;
  @include sm {
    margin-top: 0vh;
    width: 90%;
    justify-content: center !important;
    flex-direction: column !important;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-top: 4vh !important;
    padding-bottom: 4vh !important;
  }
  @include md {
    margin-top: 2vh;
    width: 95%;
    justify-content: start !important;
  }
  &__image-wrapper {
    @include sm {
      margin-left: auto !important;
      margin-right: auto !important;
      display: flex;
      align-items: center;
      justify-content: center !important;
    }
    &--image {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      @include sm {
        width: 100% !important;
        border-radius: 12px !important;
      }
    }
    &--icon {
      //   width: 70%;
    }
  }
  &__details-wrapper {
    margin-left: 4vh;

    @include sm {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: justify;
      margin-top: 3vh;
      margin-left: 5vh;
      margin-right: 5vh;
    }
    @include md {
      margin-top: 2vh;
      padding-right: 3vh;
    }
    &--title {
      font-family: $Gilroy-semi-bold !important;
      font-size: 1rem !important;
      color: #101828 !important;
      margin-bottom: 1vh !important;
      @include sm {
        font-size: 0.93rem !important;
      }
    }
    &--caption {
      font-family: $Gilroy-light !important;
      font-size: 0.85rem !important;
      color: #475467 !important;
      margin-bottom: 2vh !important;
      @include sm {
        font-size: 0.79rem !important;
      }
    }
    &--button {
      border-color: #2b3d4f !important;
      border-radius: 30px !important;
      padding: 0.5vh 2.5vh !important;
      color: #3b5269 !important;
      font-family: $Gilroy-semi-bold !important;
      font-size: 0.75rem !important;
      text-transform: none !important;
      @include sm {
        font-size: 0.73rem !important;

        margin-top: 2vh !important;
        padding: 0.5vh 2.5vh !important;
      }
      @include md {
        padding: 0.5vh 3vh !important;
        font-size: 0.7rem !important;
      }
    }
  }
}
