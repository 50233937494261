@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

//for reference
// $breakpoint-xs: 360px;
// $breakpoint-sm: 600px;
// $breakpoint-md: 900px;
// $breakpoint-lg: 1200px;
// $breakpoint-xl: 1536px;

//breakpoint functions for responsiveness

// styles for extra small devices (phones)
// Applies styles from 0px to 359px
@mixin xs {
  @media (max-width: #{$breakpoint-xs - 1px}) {
    @content;
  }
}

// Small devices (large phones)
// Applies styles from 360px to 599px
@mixin sm {
  @media (min-width: #{$breakpoint-xs}) and (max-width: #{$breakpoint-sm - 1px}) {
    @content;
  }
}

// Medium devices (tablets)
// Applies styles from 600px to 899px
@mixin md {
  @media (min-width: #{$breakpoint-sm}) and (max-width: #{$breakpoint-md - 1px}) {
    @content;
  }
}

// Large devices (small laptops, large tablets)
// Applies styles from 900px to 1199px
@mixin lg {
  @media (min-width: #{$breakpoint-md}) and (max-width: #{$breakpoint-lg - 1px}) {
    @content;
  }
}

// Extra large devices (larger laptops and desktops)
// Applies styles from 1200px and above
@mixin xl {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}
