.footer-wrapper {
  background-color: $secondary-app-color !important;
  width: 100%;
  // padding: 2.5vh 0vh !important;
  
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2.5vh;
  // margin-top : 30vh;
  @include sm {
    width: 100%;
  }
  @include md {
    width: 100%;
  }
  &__text {
    font-size: 1.05 rem !important;
    font-family: $Gilroy-regular !important;
    color: #dfdfdf !important;
    @include sm {
      font-size: 0.7rem !important;
    }
  }
  &__link {
    text-decoration: underline !important;
    font-size: 1.05 rem !important;
    font-family: $Gilroy-regular !important;
    color: #dfdfdf !important;
    @include sm {
      font-size: 0.7rem !important;
    }
  }
}
