.faq-wrapper {
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 7vh 5vh;
  background-color: #fff !important;
  box-shadow: none;
  margin-top: 3vh;
  &__title {
    margin-bottom: 4vh !important;
    font-size: 1.3rem !important;
    font-family: $default-font-family !important;
    color: #000 !important;
    @include sm {
      font-size: 1.3rem !important;
    }
  }
  &__accordion {
    margin-bottom: 2vh !important;
    width: 100%;
    box-shadow: none !important;
    &.expanded {
      background-color: #f5faff !important;
      border-radius: 15px !important;
    }

    &.collapsed {
      background-color: #fff !important;
    }
    &--heading {
      font-size: 1.05rem !important;
      font-family: $default-font-family !important;
      color: $secondary-app-color !important;
      @include sm {
        font-size: 0.95rem !important;
      }
      @include md {
        font-size: 0.95rem !important;
      }
    }
    &--description {
      // padding: 1vh 4vh !important;
      line-height: 24px !important;
      font-size: 0.9rem !important;
      font-family: $Gilroy-light !important;
      color: #000 !important;
      .css-15v22id-MuiAccordionDetails-root {
        padding: 8px 34px 16px;
      }
      @include sm {
        font-size: 0.8rem !important;
      }
      @include md {
        font-size: 0.75rem !important;
      }
    }
  }
}
