.payment {
  overflow: hidden !important;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__image {
    margin-top: 6vh;
    margin-bottom: 6.5vh;
    width: 25%;
    @include sm {
      width: 70%;
      margin-top: 3vh;
    }
    @include md {
      width: 45%;
      margin-top: 7vh;
    }
  }
  &__title {
    font-size: 2.4rem !important;
    font-family: $Gilroy-semi-bold !important;
    color: $secondary-app-color !important;
    text-transform: capitalize !important;
    margin-bottom: 3vh !important;
    @include sm {
      font-size: 2.2rem !important;
    }
    @include md {
      font-size: 2.4rem !important;
    }
  }
  &__description-container {
    text-align: center;
  }
  &__description {
    font-size: 0.95rem !important;
    font-family: $Gilroy-light !important;
    color: #000 !important;
    // text-transform: capitalize !important;
    @include sm {
      font-size: 0.9rem !important;
    }
    @include md {
      font-size: 0.925rem !important;
    }
  }
  &__button {
    text-transform: capitalize !important;
    border-radius: 30px !important;
    border-color: #2b3d4f !important;
    padding: 0.5vh 7vh !important;
    color: #3b5269 !important;
    font-size: 0.85rem !important;
    font-family: $Gilroy-bold !important;
    margin-bottom: 4vh !important;
    margin-top: 4vh !important;
    background-color: #fff !important;
    cursor: pointer !important;
    @include sm {
      font-size: 0.75rem !important;
    }
    @include md {
      font-size: 0.8rem !important;
    }
  }
  &__redirect-text {
    font-size: 0.9rem !important;
    color: #000 !important;
    font-family: $Gilroy-light !important;
    @include sm {
      font-size: 0.85rem !important;
      text-align: center;
    }
    @include md {
      font-size: 0.875rem !important;
    }
  }
}
