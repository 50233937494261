//General variables
$body-color: #f8f8f8;

//Fonts
$default-font-family: "Gilroy Medium", sans-serif;
$Gilroy-semi-bold: "Gilroy SemiBold", sans-serif;
$Gilroy-light: "Gilroy Light", sans-serif;
$Gilroy-bold: "Gilroy Bold", sans-serif;
$Gilroy-regular: "Gilroy Regular", sans-serif;

//nunito fonts
$Nunito-medium: "Nunito-Medium", sans-serif;

//Theme Color Variables
$primary-color: #39cccc;
$secondary-color: #165c5c;
$secondary-app-color: #2b3d4f;
$dark-grey: #646e83;
$grey-700: #344054;
$color-g: #4CAF50;

// MUI breakpoints
$breakpoint-xs: 360px;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

//box shadow
$smooth-shadow: 0.2px 0.7px 2.9px rgba(0, 0, 0, 0.02),
  0.5px 1.7px 7px rgba(0, 0, 0, 0.028), 0.9px 3.3px 13.1px rgba(0, 0, 0, 0.035),
  1.6px 5.8px 23.5px rgba(0, 0, 0, 0.042),
  2.9px 10.9px 43.9px rgba(0, 0, 0, 0.05), 7px 26px 105px rgba(0, 0, 0, 0.07);
