//course content tab
.course-content-tab-wrapper {
  z-index: 2;
  position: sticky;
  // top: 100px;
  top: 1px;
  border: 0.5px solid #2b3d4f;
  background-color: #fff;
  margin-bottom: 4vh !important;
  &__tabs {
    &--tab {
      font-size: 1rem !important;
      font-weight: 700 !important;
      text-transform: capitalize !important;

      font-family: $Gilroy-regular !important;
      border-right: 1px solid #2b3d4f;
      @include md {
        font-size: 0.9rem !important;
      }
      &muitab-root {
        text-transform: none;
        font-size: 0.9rem;
        padding: 1vh;
      }
    }
  }
}
