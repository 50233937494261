.notification_wrapper {
  position: fixed;
  top: 8vh;
  right: 0;
  width: 30%;
  height: 100vh;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-family: $Gilroy-light !important;
  &__bar {
    padding: 1vh 2vh;
    color: black;
    font-size: 20px;
    font-family: $Gilroy-semi-bold;
    display: grid;
    grid-template-columns: 1fr auto auto; /* Three columns: flexible space, button, and icon */
    align-items: center; /* Vertical alignment */
    gap: 10px; /* Space between columns */
  }
  &__buttons {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 0.9rem;
  }
  &__close {
    cursor: pointer;
  }
  &__green {
    color: rgb(31, 196, 31) !important;
  }
  &__message {
    display: flex;
    align-items: flex-start; /* Aligns content at the top */
    padding: 16px;
    border-bottom: 1px solid #eee; /* Optional: to separate messages */
  }
  &__avatar {
    width: 20px !important; /* Adjust size as needed */
    height: 20px !important;
    padding: 10px !important;
    border-radius: 50%;
    background-color: #ddd; /* Placeholder for the avatar */
    margin-right: 16px;
    color: black !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    font-family: $Gilroy-semi-bold !important;
  }
  &__content {
    display: flex;
    flex-direction: column;
    &__name {
      font-size: 1rem !important;
      color: #777;
      margin-bottom: 4px;
      text-transform: capitalize !important;
    }
    &_time {
      font-size: 0.85rem !important;
      color: #777;
      margin-bottom: 4px;
    }
    &-feedback {
      font-size: 0.85rem !important;
      color: #333;
    }
  }
  &-icon-wrapper {
    position: relative;
    display: inline-block;
  }
  &--unread-count {
    position: absolute;
    top: -8px;
    right: -10px;
    color: black;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: bold;
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  /* Tablets */
  .notification_wrapper {
    width: 40%; /* Slightly wider on tablets */
  }
}

@media (max-width: 768px) {
  /* Small Tablets / Large Phones */
  .notification_wrapper {
    width: 60%; /* Wider for small tablets and large phones */
    height: 70vh; /* Reduce height for smaller devices */
    top: 15vh; /* Adjust top position */
  }
}

@media (max-width: 576px) {
  /* Mobile Phones */
  .notification_wrapper {
    width: 80%; /* Full width for mobile devices */
    height: auto; /* Allow height to adjust based on content */
    top: 10vh; /* Adjust top position */
  }
}