.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10vh;
  margin-left: auto !important;
  margin-right: auto !important;
  background-color: #f5faff;
  &__title {
    font-family: $Gilroy-bold !important;
    font-size: 2.5rem !important;
    color: #2b3d4f !important;
    text-transform: capitalize !important;
  }
  &__caption {
    font-family: $Gilroy-bold !important;
    font-size: 1rem !important;
    color: #2b3d4f !important;
  }
}
