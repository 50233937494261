.topic-wrapper {
  width: 75%;
  padding: 2vh 3vh;
  box-shadow: none;
  background-color: #f5faff !important;
  display: flex;
  align-items: start;
  justify-content: space-between;
  @include md {
    width: 90%;
    padding: 2vh 2vh;
  }
  &__icon-wrapper {
    margin: 1vh !important;
    width: 35%;
    padding: 1vh;
    height: 3vh;
    background-color: #cfecec !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include md {
      margin: 0vh !important;
      width: 30%;
    }
    &--icon {
      color: #2b3c4e !important;
      width: 65%;
      @include md {
        width: 89%;
      }
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding-left: 1vh;
    padding-top: 1vh;
    gap: 0.5rem;

    &--title {
      font-size: 1rem;
      font-family: $Gilroy-regular !important;
      color: #000;
      @include md {
        font-size: 0.95rem;
      }
    }
    &--caption {
      font-size: 0.8rem !important;
      font-family: $Gilroy-light !important;
      color: #000;
      @include md {
        font-size: 0.75rem;
      }
    }
    &--link {
      font-size: 1rem !important;
      font-family: $Gilroy-regular !important;
      color: #000 !important;
      @include md {
        font-size: 0.95rem;
      }
    }
  }
}
