.dropdown {
  &__button {
    font-family: $Gilroy-semi-bold !important;
    background: #e3f1ff !important;
    color: #3b5269 !important;
    border-radius: 8px !important;
    @include sm {
      font-size: 0.75rem !important;
    }
  }

  &__menu {
    .MuiPaper-root {
      overflow-y: auto;
      padding-bottom: 2vh !important;
      margin-top: 3vh;
      border-radius: 25px;
      min-width: 230px;
      color: rgb(55, 65, 81);
      box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      @include sm {
        border-radius: 0 !important;
      }

      .MuiMenu-list {
        padding: 0;
      }
      @include sm {
        min-width: 100% !important;
        min-height: 100% !important;
        top: 34px !important;
        left: 0px !important;
      }
    }
  }

  &__profile {
    margin-bottom: 16px;
    border-radius: 25px 25px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #e3f1ff;
    @include sm {
      border-radius: 0 !important;
      padding: 1vh !important;
      margin-bottom: 10px;
    }
  }

  &__avatar {
    font-family: $Gilroy-bold;
    margin-top: 2vh !important;
    margin-bottom: 1.8vh !important;
    @include sm {
      width: 90px !important;
      height: 90px !important;
    }
  }

  &__name {
    font-family: $Gilroy-semi-bold !important;
    margin-bottom: 0.4vh !important;
    font-size: 1.25rem !important;
    color: #3b5269;
    @include sm {
      font-size: 1.7rem !important;
    }
  }

  &__id {
    text-transform: uppercase;
    font-family: $Gilroy-semi-bold !important;
    font-weight: 100 !important;
    color: #3b5269;
    font-size: 0.875rem !important;
    margin-bottom: 2vh !important;
    opacity: 60%;
    @include sm {
      font-size: 1rem !important;
    }
  }

  &__menu-item {
    font-size: 1rem !important;
    font-family: $default-font-family !important;
    color: $dark-grey !important;
    &:active {
      background-color: rgba(0, 0, 0, 0.04);
    }

    .MuiSvgIcon-root {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.54);
      margin-right: 12px;
      @include sm {
        font-size: 0.5rem !important;
      }
    }
  }
}

// .dropdown__menu {
//   @include sm {
//     min-width: 180px !important;
//   }
// }

.chevron-down-logo {
  @include sm {
    width: 21% !important;
  }
}
