.search-bar-card-wrapper {
  width: 95%;
  height: 55%;
  background-color: $secondary-app-color !important;
  padding: 5vh 5vh !important;
  border-radius: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  @include sm {
    width: 78%;
    margin-bottom: 0vh !important;
  }
  @include md {
    width: 86%;
    margin-bottom: 2vh !important;
  }
  &__title {
    position: relative;
    font-size: 2.6rem !important;
    color: #fff;
    font-family: $Gilroy-semi-bold !important;
    margin-bottom: 3vh !important;
    @include sm {
      font-size: 1.4rem !important;
      margin-bottom: 2.5vh !important;
    }
    @include md {
      font-size: 2.25rem !important;
      margin-bottom: 1.5vh !important;
    }
  }
  &__image {
    position: absolute;
    top: 40px;
    right: 306px;
    width: 8%;
    opacity: 0.7;
    @include sm {
      display: none;
    }
    @include md {
      top: 235px;
      right: 104px;
      width: 10%;
    }
  }
  &__search-bar {
    width: 35% !important;
    background-color: #fff !important;
    color: #667085 !important;
    border-radius: 8px !important;
    border-style: none !important;
    padding: 0.1vh 2vh !important;
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border-style: none !important;
      outline: none !important;
    }
    @include sm {
      width: 85% !important;
    }
    @include md {
      width: 60% !important;
      padding: 0.1vh 1vh !important;
    }
  }
  &__dropdown{
    position: absolute;
    top: 100%; /* Positions it right below the TextField */
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 1; /* Ensures it overlays other elements */
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  &__loading {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}
.search-bar-wrapper {
  position: relative;
}
.search-bar-dropdown {
  position: absolute;
  top: calc(100% + 5px); /* Places it just below the card */
  left: 50%;
  transform: translateX(-50%);
  width: 40%; /* Adjust as needed */
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 2; /* Ensures it overlays the card */
  border: 1px solid #ddd;
  border-radius: 12px;
  font-family: $Gilroy-light;
}