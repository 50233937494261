// .video-player-wrapper {
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   background-color: aliceblue;
//   width: 100%;
//   height: 100%;
//   &__video-player {
//     width: 908px !important;
//     height: 447px !important;
//   }
// }

.player-wrapper-course-view {
  padding: 2vh 4vh 2vh !important;
  margin-top: 4vh !important;
  position: relative;
  height: 70vh !important;
  border-radius: 30px !important;
  overflow: hidden !important;
  @include md {
    height: 25vh !important;
    margin-top: 2vh !important;
    height: 40vh !important;
    margin-bottom: 3vh !important;
  }
  @include sm{
    height: 30vh !important;
    margin-bottom: 2vh !important;
  }
  &#lesson {
    // display: flex;
    // align-items: center;
    margin-top: 3vh !important;
    border-radius: 30px;
    width: 115vh;
    margin-left: auto;
    margin-right: auto;

    padding: 5vh 4vh 2vh !important;
    // width: 50vh !important;
  }
}
.player-wrapper {
  padding: 2vh 4vh 2vh !important;
  margin-top: 4vh !important;
  position: relative;
  height: 50vh !important;
  border-radius: 30px !important;
  overflow: hidden !important;
  @include md {
    height: 25vh !important;
    margin-top: 2vh !important;
  }
  &#lesson {
    // display: flex;
    // align-items: center;
    margin-top: 3vh !important;
    border-radius: 30px;
    width: 115vh;
    margin-left: auto;
    margin-right: auto;

    padding: 5vh 4vh 2vh !important;
    // width: 50vh !important;
  }
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;

  &_youtube{
    width: 100% !important;
    height: 100% !important;
  }
}

.plyr-react{
  width: auto !important;
  height: auto !important;
  position: absolute !important;
  top: 0;
  left: 0;
}

.react-player__preview {
  &--image {
    object-fit: cover !important;
    width: 100% !important;
    @include sm {
      width: 100% !important;
      height: 100% !important;
      border-radius: 30px;
    }
    @include md {
      width: 100% !important;
      height: 100% !important;
      border-radius: 30px;
    }
  }




  .play-button {
    position: absolute;
    //transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background-color: #efefef;
    color: #39cccc;
    border: "2px solid  #39cccc" !important;
   
    border: 2px solid #39cccc;
    padding: 10px 20px;
    font-size: 16px;
    //cursor: pointer;
    border-radius: 50%;
    border-color: #39cccc;
   
  }
}
.react-player__shadow {
  background-color: #fff !important;
 // border: 2px solid #39cccc;
  background-image: none !important;
}
.react-player__play-icon {
 // border-color: transparent transparent transparent #39cccc !important;
}


.bunney-net-player {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.responsive-iframe {
  width: 100%;
  height: 40vh;
  max-width: 100%;
  border: none;
}