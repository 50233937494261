.navigate-chapter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff !important;
  padding: 3vh 3.5vh !important;
  @include sm {
    padding: 3vh 1.5vh !important;
  }
  @include md {
    padding: 2vh 1.5vh !important;
  }
  &#no-bg {
    margin-left: 3vh !important;
    width: 90%;
    background-color: transparent !important;
    @include sm {
      margin-left: 1vh !important;
    }
    @include md {
      margin-left: 2vh !important;
    }
  }

  &__button {
    font-size: 0.85rem !important;
    color: #3b5269 !important;
    padding: 0.5vh 5vh !important;
    font-family: $Gilroy-bold !important;
    border-radius: 30px !important;
    border-color: #2b3d4f !important;
    text-transform: capitalize !important;
    position: relative;
    margin-top: 2vh !important;
    @include sm {
      padding: 0.5vh 3vh !important;
      font-size: 0.7rem !important;
    }
    @include md {
      padding: 0.5vh 2vh !important;
      font-size: 0.68rem !important;
    }
    &#next {
      margin-right: 2vh !important;
      @include sm {
        margin-right: 0vh !important;
      }
    }
    &#white-bg {
      background-color: #fff !important;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -24px;
      height: 2px;
      background-color: #3b5269;
      transition: opacity 0.3s;
      opacity: 0;
    }

    &.active::after {
      opacity: 1;
    }
  }
  &__title {
    font-size: 1.5rem !important;
    color: #242222 !important;
    font-family: $default-font-family !important;
    @include sm {
      font-size: 1rem !important;
    }
    @include md {
      font-size: 0.95rem !important;
    }
  }
  &__progress {
    width: 105%;
    height: 7px !important;
    border-radius: 0px !important;
    background-color: #ffffff !important;
    @include sm {
      width: 105% !important;
      margin-right: 6vh !important;
      border-radius: 0px !important;
    }
    @include md {
      width: 106% !important;
      margin-right: 0vh !important;
    }
    .css-5xe99f-MuiLinearProgress-bar1 {
      background-color: $secondary-app-color !important;
    }
  }
}

.navigate-chapter-bottom{
  margin-top: 2vh;
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: space-between;
  margin-left: 2%;
  position: sticky;
}