//gilroy fonts
@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/gilroy_font/Gilroy-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/gilroy_font/Gilroy-BlackItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Gilroy Regular";
  src: url("../../assets/fonts/gilroy_font/Gilroy-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Gilroy SemiBold";
  src: url("../../assets/fonts/gilroy_font/Gilroy-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Gilroy Bold";
  src: url("../../assets/fonts/gilroy_font/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/gilroy_font/Gilroy-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy Light";
  src: url("../../assets/fonts/gilroy_font/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy Medium";
  src: url("../../assets/fonts/gilroy_font/Gilroy-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/gilroy_font/Gilroy-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/gilroy_font/Gilroy-UltraLight.ttf")
    format("truetype");
}
