.report-content-card {
  margin-top: 2vh !important;
  box-shadow: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 600px;
  position: relative !important;
  border-radius: 30px !important;
  overflow: hidden !important;
  padding: 6vh 6vh;
  @include sm {
    padding: 3vh 3vh !important;
  }
  @include md {
    padding: 3vh 3vh !important;
    margin-top: 0 !important;
  }

  &__question {
    font-family: $default-font-family !important;
    color: #000 !important;
    margin-top: 1vh !important;
    margin-bottom: 0.5vh !important;
    font-size: 1.45rem !important;
    @include sm {
      font-size: 1rem !important;
      margin-bottom: 1.5vh !important;
    }
    @include md {
      font-size: 1.6rem !important;
      margin-bottom: 1.5vh !important;
    }
  }
  &__radio-group {
    gap: 1vh !important;
    padding: 3vh 1vh 7vh !important;
    &#radio-group-2 {
      padding: 4vh 1vh 10vh !important;
      @include md {
        padding: 3vh 1vh 6vh !important;
      }
    }
    @include sm {
      gap: 2vh !important;
      padding: 2.5vh 1vh !important;
    }
    @include md {
      gap: 2vh !important;
      padding: 3.5vh 1vh !important;
    }
  }
  &__form-control {
    padding: 1.5vh !important;
    gap: 2vh !important;
    @include sm {
      gap: 1vh !important;
      padding: 0.8vh !important;
    }
    &-activity {
      color: #000 !important;
      margin-top: 1vh !important;
      margin-bottom: 1vh !important;
      font-size: 1rem !important;
      font-family: $Gilroy-regular !important;
      @include sm {
        font-size: 0.77rem !important;
      }
      @include md {
        font-size: 1.1rem !important;
      }
      &-type {
        font-size: 0.89rem !important;
        font-family: $Gilroy-light !important;
        color: #000 !important;
        @include sm {
          font-size: 0.7rem !important;
        }
      }
    }
    &--button {
      margin-top: 1.7vh !important;
      margin-left: 1.2vh !important;
      margin-bottom: 1.5vh !important;
      width: 18% !important;
      border-radius: 30px !important;
      border-color: #2b3d4f !important;
      color: #3b5269 !important;
      padding: 0.5vh 9vh !important;
      font-size: 0.78rem !important;
      font-family: $Gilroy-bold !important;
      text-transform: none !important;
      @include sm {
        width: 10% !important;
        font-size: 0.59rem !important;
        margin-left: 11px !important;
        padding: 0.8vh 8vh !important;
        margin-top: 1.4vh !important;
        margin-bottom: 0.7vh !important;
      }
      @include md {
        width: 20% !important;
        font-size: 0.78rem !important;
        margin-left: 1.3vh !important;
        margin-bottom: 1vh !important;
        padding: 0.8vh 8vh !important;
        margin-top: 1.5vh !important;
      }
      &--btn {
        width: 18% !important;
        padding: 0.7vh 15vh !important;
        border-radius: 30px !important;
        font-size: 0.78rem !important;
        font-family: $Gilroy-bold !important;
        color: #3b5269 !important;
        border-color: #2b3d4f !important;
        text-transform: none !important;
        @include sm {
          width: 10% !important;
          font-size: 0.57rem !important;
          /* margin-left: 11px !important; */
          padding: 0.8vh 6.5vh !important;
          margin-top: 1.4vh !important;
          margin-bottom: 0.7vh !important;
        }
        @include md {
          width: 20% !important;
          font-size: 0.78rem !important;
          margin-left: 1.3vh !important;
          margin-bottom: 2vh !important;
          padding: 0.8vh 10vh !important;
          margin-top: 1.5vh !important;
        }
        &#contained {
          background-color: #2b3d4f !important;
          color: #fafdff !important;
          box-shadow: none !important;
        }
      }
    }
  }
  &__button-wrapper {
    display: flex;
    justify-content: space-around;
  }
  &__progress-status {
    background-color: #39cccc !important;
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 4% !important;
    border-radius: 0 0 0 30px !important;
    
  }

  //form

  &__form {
    padding-top: 2vh !important;
    padding-bottom: 2vh !important;
  }
  &__text-field {
    margin-bottom: 1.5vh !important;
    
  }
  &__input-label {
    font-family: $Gilroy-light !important;
    margin-bottom: 1.5vh !important;
    font-size: 0.9rem !important;
    color: #344054 !important;
  }
}

// report content header scss

.report-content-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center !important;
  margin-top: 8vh !important;
  gap: 3vh !important;
  @include sm {
    margin-top: 4vh !important;
  }
  @include md {
    margin-top: 3vh !important;
  }
  &__title {
    font-family: $Gilroy-semi-bold !important;
    font-size: 2.55rem !important;
    color: #000 !important;
    @include sm {
      font-size: 1.7rem !important;
    }
    @include md {
      font-size: 2.56rem !important;
    }
  }
  &__text {
    font-family: $Gilroy-regular !important;
    font-size: 1.25rem !important;
    color: #646e83 !important;
    margin-bottom: 1.5vh !important;
    @include sm {
      font-size: 0.77rem !important;
    }
    @include md {
      margin-top: 1vh !important;
    }
    #caption {
      @include sm {
        margin-top: 1vh !important;
      }
      @include md {
        margin-top: 0.5vh !important;
      }
    }
  }
}
