//-------------------------- header component css
.header-container {
  margin-top: 8% !important;
  text-align: left;
}

.header-container-title {
  font-family: $Gilroy-semi-bold !important;
  margin-top: 3vh !important;
  font-size: 2.4rem !important;
  @include sm {
    font-size: 1.8rem !important;
  }
  @include md {
    font-size: 2rem !important;
  }
}
.header-container-subtitle {
  font-family: $Gilroy-regular !important;
  color: $dark-grey !important;
  font-size: 1.2em !important;
  font-weight: 200 !important;
  @include sm {
    font-size: 1rem !important;
  }
  @include md {
    font-size: 1.1rem !important;
  }
}
