.quiz_result_wrapper {
    margin: 30px;

    .quiz-result-card {
        display: flex;
        align-items: center;
        padding-left: 5vh;
        padding-right: 5vh;
        padding-top: 6vh;
        padding-bottom: 6vh;
        background-color: #fff;
        width: 100%;

        &_quiz-name{
            font-family: $Gilroy-semi-bold !important;
            width:100% !important;
            
            
        }

        &_border-green {
            border-left: 8px solid green;
        }

        &_border-grey {
            border-left: 8px solid $dark-grey;
        }
     

        &__subbox {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;
            margin-bottom: 2rem;

            &-button {
                border-radius: 16px !important;
                padding: 0.5rem 2.5rem !important;

                &--green {
                    background-color: $color-g !important;
                }

                &--grey {
                    background-color: $dark-grey !important;
                }
            }
        }
    }

   

    // Mobile (max-width: 600px)
    @media (max-width: 600px) {
        .quiz-result-card {
            padding-left: 2vh;
            padding-right: 2vh;
            padding-top: 3vh;
            padding-bottom: 3vh;

            &__subbox {
                flex-direction: column;
                margin-top: 1rem;
                margin-bottom: 1rem;

                &-button {
                    padding: 0.5rem 2rem !important;
                    width: 100%;
                }
            }
        }
    }

    // Tablet (max-width: 1024px)
    @media (max-width: 1024px) {
        .quiz-result-card {
            padding-left: 3vh;
            padding-right: 3vh;
            padding-top: 4vh;
            padding-bottom: 4vh;

            &__subbox {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;

                &-button {
                    padding: 0.5rem 2.5rem !important;
                }
            }
        }
    }

    // Desktop (min-width: 1025px)
    @media (min-width: 1025px) {
        .quiz-result-card {
            padding-left: 5vh;
            padding-right: 5vh;
            padding-top: 6vh;
            padding-bottom: 6vh;

            &__subbox {
                margin-top: 2rem;
                margin-bottom: 2rem;

                &-button {
                    padding: 0.5rem 2.5rem !important;
                }
            }
        }
    }
}


.quiz-view-all-modal{
    margin: 1.5rem 1.5rem !important; outline: none !important; border-radius: 8px !important; background-color: #F5FAFF !important; padding: 2rem !important;
    font-family:  $Gilroy-semi-bold !important; width:100% !important;
    
   &__answer{
        border-radius: 0 !important; border-left: 8px solid green !important; background-color: white !important; padding: 2rem !important;
            

    }
    &__feedback{
        border-radius: 0 !important; background-color: #E2FFEA !important; padding: 2rem !important;
    }
}
.css-3su884-MuiPaper-root{
    box-shadow: none !important;
}