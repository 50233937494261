.help-center-header {
  margin-top: 3vh !important;
  margin-bottom: 3vh !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 1rem;
  @include sm {
    margin-top: 4vh !important;
  }
  @include md {
    margin-top: 5vh !important;
    margin-bottom: 1vh !important;
  }
  &__icon-wrapper {
    margin: 1vh !important;
    width: 3.5%;
    padding: 0.5vh;
    height: 4vh;
    background-color: #cfecec !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    //display set to none for help-center three page using id

    &#displayImage {
      display: none !important;
    }
    @include sm {
      width: 12.5%;
    }
    @include md {
      margin: 0vh !important;
      width: 4.5%;
      padding: 0.5vh;
      height: 3vh;
    }
    &--icon {
      width: 50%;
      @include sm {
      }
    }
  }
  &__details {
    &--title {
      font-size: 1.4rem !important;
      font-family: $Gilroy-semi-bold !important;
      color: #000;
      margin-bottom: 1vh !important;
      @include sm {
        font-size: 1.3rem !important;
      }
      @include md {
        font-size: 1.35rem !important;
      }
    }
    &--caption {
      font-size: 0.95rem;
      font-family: $Gilroy-light !important;
      color: #000;
      margin-bottom: 5vh !important;
      @include sm {
        font-size: 0.85rem !important;
        margin-bottom: 2vh !important;
      }
      @include md {
        font-size: 0.9rem !important;
        margin-bottom: 3vh !important;
      }
    }
  }
}
