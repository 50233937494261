.course-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  width: 100%;
  &__progress-wrapper {
    width: 85%;
    &--title {
      font-family: $Gilroy-semi-bold !important;
      font-size: 1.3rem !important;
      color: $secondary-app-color !important;
      margin-bottom: 5vh !important;
      margin-top: 9vh !important;
      @include sm {
        text-align: center;
        margin-top: 4vh !important;
      }
      @include md {
        margin-top: 6vh !important;
        margin-bottom: 3.5vh !important;
      }
    }
    &--progress-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      margin-bottom: 1vh !important;
      &--percentage {
        font-family: $Gilroy-light !important;
        color: #575757 !important;
        font-size: 0.75rem !important;
      }
      &--chapter {
        font-family: $Gilroy-light !important;
        color: #575757 !important;
        font-size: 0.75rem !important;
      }
    }
  }
  &__back-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    @include sm {
      margin-bottom: 3vh !important;
    }
    &--link {
      display: flex;
      align-items: center;
      width: 100% !important;
      &-icon {
        transform: rotate(90deg);
        width: 100% !important;
      }
    }
    &--text {
      font-size: 0.9rem !important;
      font-family: $Gilroy-regular !important;
      color: $secondary-app-color;
      @include md {
        font-size: 0.7rem !important;
      }
    }
  }
}

.course-chapter-progress-wrapper {
  display: flex;
  align-items: center;
  &__progress {
    height: 7px !important;
    border-radius: 15px !important;
    background-color: #ffffff !important;
    @include sm {
      width: 100% !important;
      margin-right: 6vh !important;
    }
    @include md {
      width: 100% !important;
      margin-right: 6vh !important;
    }
    .css-5xe99f-MuiLinearProgress-bar1 {
      background-color: $secondary-app-color !important;
    }
    &-text {
      font-family: $default-font-family !important;
      font-size: 0.5rem;
      @include sm {
        margin-left: 1vh !important;
        font-size: 0.69rem !important;
      }
      @include md {
        margin-left: 1vh !important;
        font-size: 0.75rem !important;
      }
    }
  }
}
