.quiz_feedback{
    padding: 16vh 6vh !important;
  border-top: none !important;

    &_title{
        font-family: $Gilroy-semi-bold !important;
        line-height: 20px !important;
        font-size: 44px !important;
        margin-top: 8vh !important;
    }
    &_para{
        font-family: $Gilroy-light !important;
        line-height: 24px !important;
        font-size: 18px !important;
        margin-top: 4vh !important;
        // word-spacing: 16px !important;
        letter-spacing: 4px !important;
    } 
}