.progress-bar-wrapper {
  width: 100%;
  box-shadow: none !important;
  background-color: #fff;
  overflow: hidden;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 0 0 35px 35px !important;
  @include sm {
    position: absolute;
    bottom: -7px;
    left: -7px;
    width: 105% !important;
    // height: 65%;
    // width: 97% !important;
    // margin-top: 4vh;
  }
  @include md {
    width: 58%;
    position: absolute;
    bottom: -388px;
    left: 162px;
    margin-bottom: 3vh !important;
  }
  &__progress {
    border-radius: 0 0 0 13px !important;
    height: 100%;
    background-color: #cee7ff;
    transition: width 0.5s ease-in-out;
  }
  &__text {
    font-family: $Gilroy-light !important;
    position: absolute;
    right: 37px;
    color: #000;
    display: flex;
    align-items: center;
    @include sm {
      font-size: 0.75rem !important;
    }
  }
  &__icon {
    width: 13% !important;
    margin-right: 1vh !important;
    @include sm {
      width: 12% !important;
    }
  }
}
