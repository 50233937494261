.course-review-card-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  background-color: #fff !important;
  box-shadow: none;
  border-radius: 30px !important;
  width: 95%;
  height: 60%;
  padding: 5vh 3vh !important;
  margin-top: 2vh !important;
  // margin-right: 2rem;
  @include sm {
    width: 100%;
    padding: 5vh 4vh !important;
  }
  &__added{
    font-size: 0.65rem !important;
    font-family: $Gilroy-light !important;
    color: #000 !important;
  }
  &__user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    &--details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      &-name {
        font-size: 1.05rem !important;
        font-family: $Gilroy-semi-bold !important;
        color: #3b5269 !important;
        @include sm {
          white-space: nowrap;
          font-size: 1.1rem !important;
        }
      }
      &-rating {
        font-size: 0.85rem !important;
        @include sm {
          font-size: 1rem !important;
        }
      }
    }
  }
  &__review {
    margin-top: 1.5vh !important;
    font-size: 0.85rem !important;
    font-family: $Gilroy-light !important;
    color: #000 !important;
    line-height: 25px !important;
    // padding-right: 3vh !important;
    @include sm {
      padding-right: 0vh !important;
    }
    @include sm {
      padding-right: 1vh !important;
    }
  }
}
