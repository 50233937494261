//import nunito google font
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

// BASE
@import "base/fonts";
@import "base/reset";
@import "base/typography";

//SASS-UTILS
@import "sass-utils/variables";
@import "sass-utils/functions";
@import "sass-utils/helpers";
@import "sass-utils/mixins";

// COMPONENTS
@import "components/footer";
@import "components/snackbar";
@import "components/forms";
@import "components/dropdown";
@import "components/header";
@import "components/profile";
@import "components/security";
//dashboard
@import "components/dashboardcard";
@import "components/progressbar";
@import "components/dashboardquiz";

@import "components/togglebutton";
@import "components/purchasehistory";
@import "components/courseinfo";
@import "components/discipleinschool";
@import "components/reportcontentcard";
@import "components/circularprogress";
@import "components/activityanalyticscard";
@import "components/courseforumcard";
@import "components/engagecard";
@import "components/courseoutline";
@import "components/courseactivitystats";

//help center
@import "components/searchbarcard";
@import "components/topic";
@import "components/contactuscard";
@import "components/breakcrumb";
@import "components/helpcenterheader";
@import "components/settingupaccount";
@import "components/htmllogincard";

//course-details
@import "components/faqcard";
@import "components/courseprice";
@import "components/coursedetails";
@import "components/videoplayer";
@import "components/courseoverview";
@import "components/cirriculumcard";

@import "components/coursereview";
@import "components/coursereviewcard";
@import "components/coursecontenttab";
@import "components/payment";

// notification
@import "components/notification";

//course lesson
@import "components/coursetimeline";
@import "components/timeline";
@import "components/navigatechapter";
@import "components/pdfcomp";
@import "components/chaptercontent";
@import "components/lessonMCQ";
@import "components/singleanswerquiz";
@import "components/questionanswer";
@import "components/quizFeedback";
@import "components//quizresultcard";

//PAGES
@import "pages/\ _auth.scss";
@import "pages/courseviewpage";
@import "pages/dashboard";
@import "pages/mycourse";
@import "pages/settingpage";
@import "pages/helpcenter";
@import "pages/errorpage";
@import "pages/public_courses";
@import "pages/verify_certificate";

// LAYOUT
@import "layout/applayout";
@import "layout/footer";
@import "layout/navbar";

body {
  padding: 0;
  margin: 0;
}

.padding-top-0 {
  padding-top: 0 !important;
}
