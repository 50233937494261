.activity-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  // height: 100%;
  border-radius: 30px;
  padding: 3vh;
  background-color: #ffffff !important;
  @include sm {
    width: 100%;
  }
  @include md {
    width: 81%;
    padding: 3vh 2vh;
  }
  &__heading {
    font-size: 3rem !important;
    font-family: $Gilroy-semi-bold !important;
    color: #f4b249 !important;
    @include sm {
      font-size: 2.5rem !important;
    }
    @include md {
      font-size: 1.8rem !important;
    }
  }
  &__caption {
    font-size: 1rem !important;
    font-family: $Gilroy-semi-bold !important;
    color: $secondary-app-color !important;
    margin-bottom: 3vh !important;
    @include sm {
      text-align: center;
      font-size: 1rem !important;
    }
    @include md {
      text-align: center;
      font-size: 1rem !important;
    }
  }
}
