.dashboard-quiz {
  border-radius: 35px;
  background-color: #e9f4ff !important;
  padding: 4vh !important;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  width: 30%;
  position: absolute;
  top: 100px;
  right: 43px;
  @include sm {
    width: 80%;
    margin-top: 3vh;
    padding: 5vh 6vh 5vh 4vh !important;
    position: static;
  }
  @include md {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 45% !important;
    margin-top: 5vh;
    position: static;
  }

  &__image {
    width: 30%;
    @include sm {
      width: 45%;
    }
    @include md {
      width: 29%;
    }
  }
  &__content {
    &--title {
      color: #000 !important;
      font-family: $Gilroy-bold !important;
      font-size: 2rem !important;
      margin-bottom: 2vh !important;
      @include sm {
        font-size: 1.5rem !important;
      }
      @include md {
        font-size: 1.5rem !important;
      }
    }
    &--description {
      color: #3b5269 !important;
      font-family: $Gilroy-light !important;
      font-size: 1.05rem !important;
      margin-bottom: 2vh !important;
      @include sm {
        font-size: 0.9rem !important;
      }
      @include md {
        font-size: 0.9rem !important;
      }
    }
  }
  &__assessment {
    border-radius: 30px;
    background-color: #fff !important;
    border: 1px solid #000000 !important;
    margin: 3vh 0vh;
    padding: 1vh 3vh;
    display: flex;
    align-items: center;
    &-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      text-decoration: none;
      gap: 4rem;
      @include sm {
        gap: 2rem;
      }
      @include md {
        gap: 2rem;
      }
      &--text {
        font-family: $default-font-family !important;
        font-size: 1.25rem !important;
        color: #000 !important;
        @include sm {
          white-space: nowrap !important;
          font-size: 1.05rem !important;
        }
        @include md {
          // white-space: nowrap !important;
          font-size: 0.95rem !important;
        }
      }
      &--button {
        width: 15%;
        @include sm {
          width: 14%;
        }
        @include md {
          width: 14%;
        }
      }
    }
  }
}
