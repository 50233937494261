.course-review-wrapper {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  // justify-content: space-between;
  padding: 7vh 0vh;
  box-shadow: none;
  margin-top: 3vh !important;
  @include sm {
    margin-top: 0vh !important;
    padding: 7vh 0vh;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000 !important;
    &-rating {
      display: flex;
      align-items: center;
      justify-content: space-around;
      &--icon {
        width: 10% !important;
        margin-right: 2vh !important;
        @include sm {
          width: 6% !important;
          margin-right: 0.5vh !important;
        }
      }
      &--text {
        white-space: nowrap !important;
        font-size: 1.05rem !important;
        font-family: $default-font-family !important;
        color: $secondary-app-color !important;
        @include sm {
          font-size: 0.95rem !important;
        }
      }
      &--title {
        white-space: nowrap !important;
        font-size: 1.25rem !important;
        font-family: $default-font-family !important;
        color: $secondary-app-color !important;
        @include sm {
          font-size: 1.05rem !important;
        }
      }
      &--value {
        white-space: nowrap !important;
        font-size: 1.5rem !important;
        font-family: $default-font-family !important;
        color: $secondary-app-color !important;
        @include sm {
          font-size: 1.25rem !important;
        }
      }
    }
    &--subheading {
      margin-top: 2vh !important;
    }
    &--button {
      font-size: 0.85rem !important;
      color: #3b5269 !important;
      font-family: $Gilroy-bold !important;
      border-radius: 30px !important;
      border: 1px solid #2b3d4f !important;
      padding: 0.5vh 5vh !important;
      @include sm {
        display: none !important;
      }
      @include md {
        font-size: 0.75rem !important;
      }
    }
  }
  &__button-box{
    margin-top: 1.5rem !important;
  }
  &__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &--buy-now {
      text-transform: none !important;
      padding: 0.75vh 0vh !important;
      border-radius: 30px !important;
      border-color: #2b3d4f !important;
      background-color: #fff !important;
      box-shadow: none !important;
      font-family: $Gilroy-bold !important;
      font-size: 0.8rem !important;
      color: #3b5269 !important;
      margin-bottom: 1.8vh !important;
      @include sm {
        font-size: 0.85rem !important;
      }
      @include md {
        padding: 0.3vh 0vh !important;
        font-size: 0.59rem !important;
        margin-bottom: 1vh !important;
      }
    }
  }
  &__scroll-wrapper {
    // overflow-x: hidden !important;
    // scroll-behavior: smooth;
    // position: relative;
    @include sm {
      width: 100%;
    }

    // &-content {
    //   display: flex;
    //   gap: 2rem;
    //   animation: scroll-horizontal 33s linear infinite;
    //   @include sm {
    //     gap: 1rem;
    //     animation: scroll-horizontal 23s linear infinite;
    //   }
    // }
  }

  // @keyframes scroll-horizontal {
  //   0% {
  //     transform: translateX(100%);
  //   }
  //   100% {
  //     transform: translateX(-100%);
  //   }
  // }
}
