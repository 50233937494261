.css-eqpfi5-MuiAccordionSummary-content {
  align-items: center !important;
  // justify-content: space-between !important;
}
.curriculum-card__timeline-wrapper__accordian--summary
  .MuiAccordionSummary-content {
  align-items: center !important;
  justify-content: space-between !important;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
  opacity: 0 !important;
}
//remove padding from timeline
.css-6l1m71-MuiTimeline-root {
  padding: 0px !important;
  // -webkit-box-flex: 0 !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0px 16px 16px;
}

.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
  padding: 0px 16px;
}
.course-timeline-wrapper {
  border-radius: 30px !important;
  margin-top: 2vh !important;
  padding: 2vh 0vh !important;
  background-color: transparent !important;
  width: 85%;

  @include sm {
    border-radius: 30px !important;
    margin-top: 1vh !important;
    padding: 2vh 0vh !important;
    width: 85%;
  }
  @include md {
    width: 85%;
    margin-top: 0vh !important;
  }
  &__circle-icon {
    width: 7%;
    @include sm {
      width: 7.5%;
    }
    @include md {
      width: 10%;
    }
    &#transparentcircle {
      width: 5.5%;
      @include sm {
        width: 6% !important;
      }
      @include md {
        width: 9% !important;
      }
    }
  }
  &__circle-icon-list {
    width: 19px;
    @include sm {
      width: 20.5px;
    }
    @include md {
      width: 17px;
    }
    &#transparent-circle {
      width: 15px !important;
      padding-left: 2px !important;
      @include sm {
        width: 16px !important;
        padding-left: 2px !important;
      }
      @include md {
        width: 13px !important;
        padding-left: 2px !important;
      }
    }
  }
  &__title {
    margin-left: 1vh !important;
    font-family: $default-font-family !important;
    font-size: 0.95rem !important;
    color: $secondary-app-color !important;
    @include sm {
      font-size: 0.9rem !important;
    }
    @include md {
      font-size: 0.8rem !important;
    }
  }
  &__accordian {
    width: 100%;
    border: 1px solid #a9a9a9 !important;
    border-radius: 20px !important;
    margin-top: 3vh !important;
    margin-bottom: 3vh !important;
    box-shadow: none !important;
    background-color: transparent !important;
    @include md {
      margin-top: 1.5vh !important;
      margin-bottom: 1.5vh !important;
    }
    &--summary {
    }
  }
  &__checklist-text {
    // font-family: $default-font-family !important;
    // font-size: 0.8rem !important;
    // color: $secondary-app-color !important;
  }

  //list
  &__list-item-text {
    font-family: $default-font-family !important;
    font-size: 0.8rem !important;
    color: $secondary-app-color !important;
  }
  &__list-item-duration {
    font-family: $Gilroy-light !important;
    font-size: 0.7rem !important;
    color: $secondary-app-color !important;
  }
}

//timeline content
.timeline-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__text {
    font-family: $Gilroy-regular !important;
    font-size: 0.85rem !important;
    color: $secondary-app-color !important;
    //make it bolder when active/select

    &.selected-lesson {
      font-weight: 900 !important;
      color: #000000 !important;
     // color: #000 !important;
    }



    @include sm {
      font-size: 0.8rem !important;
    }
    @include md {
      font-size: 0.7rem !important;
    }
  }


  &__duration {
    font-family: $Gilroy-light !important;
    font-size: 0.85rem !important;
    color: $secondary-app-color !important;
    @include sm {
      font-size: 0.7rem !important;
    }
    @include md {
      font-size: 0.75rem !important;
    }
  }
}

//timeline
.course-timeline-timeline {
  padding: 0 !important;
  flex: 0;
  &__item {
    min-height: 50px !important;
    &--connector {
      opacity: 0.5 !important;
      width: 1px !important;
    }
    &--content {
      padding: 0vh 0.8vh !important;
    }
  }
}

.locked {
  color: rgba(0, 0, 0, 0.5) !important; /* Change color to a faded version */
  cursor: not-allowed !important;
}


//course-view message
// .course-view-component-wrapper {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   height: 100%;
//   padding: 6vh;
//   gap: 3rem;
//   @include sm {
//     width: 75% !important;
//     margin-top: 0;
//   }
//   @include md {
//     margin-top: 10vh;
//     width: 69% !important;
//     height: 72%;
//     padding: 5vh 4vh 5vh 9vh;
//   }
// }
