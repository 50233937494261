.chapter-content {
  width: 100%;
  max-width: 100%;
  display: block;
  margin-top: 4vh !important;
  box-sizing: border-box;
  overflow: visible;  // Allow content to overflow rather than hide it

  &__heading {
    margin-left: 4.5vh !important;
    font-size: 1.25rem !important;
    font-family: $default-font-family !important;
    color: #242222 !important;
    margin-bottom: 4vh !important;
    
    @include md {
      font-size: 1.15rem !important;
      margin-bottom: 3vh !important;
    }
  }

  &__description {
    padding: 0vh 4.5vh !important;
    line-height: 30px !important;
    font-size: 0.95rem !important;
    font-family: $Gilroy-light !important;
    color: #000 !important;

    // Prevent breaking in the middle of words, but allow long words to wrap
    word-break: normal !important;  
    overflow-wrap: break-word !important;  // Handles long unbreakable words like URLs

    white-space: normal !important;  // Normal whitespace handling, allowing wrapping at spaces
    
    // Avoid overriding inline styles
    p {
      text-align: unset;  // Ensure no text alignment changes in paragraph
      word-break: normal !important;  // Avoid mid-word breaks in paragraphs
      overflow-wrap: break-word !important;  // Handle long words gracefully
      white-space: normal !important;  // Allow normal text wrapping in paragraphs
    }

    @include md {
      font-size: 0.9rem !important;
    }
  }
  &_table-layout{
    width: 100% !important;
    text-align: center !important;
    border: none !important;
    border-radius: 10px !important;
    // border-color: #000 !important;
  }
}
