.security-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    align-self: start;
    color: $secondary-app-color !important;
    font-size: 2.18rem !important;
    @include sm {
      font-size: 1.5rem !important;
    }
    @include md {
      font-size: 1.8rem !important;
    }
  }
}

.security-form {
  margin-top: 3vh !important;
  background-color: white;
  padding: 7.95vh 4vh 5vh;
  border-radius: 30px;
  &__input-label {
    color: $grey-700 !important;
    font-weight: 200 !important;
    font-size: 1rem !important;
    margin-bottom: 1vh !important;
    padding-bottom: 1.4vh !important;
    @include sm {
      font-size: 0.8rem !important;
    }
    @include md {
      font-size: 0.9rem !important;
    }
  }
  &__button {
    // margin-left: 3vh !important;
    text-transform: none !important;
    font-family: $Gilroy-bold !important;
    font-size: 0.85rem !important;
    padding: 0.7vh 2.5vh !important;
    align-self: flex-end;
    text-transform: none;
    border-radius: 30px !important;
    color: #3b5269 !important;
    border-color: #2b3d4f !important;
    @include sm {
      font-size: 0.65rem !important;
    }
    @include md {
      font-size: 0.8rem !important;
    }
  }
}
