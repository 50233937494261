//purchase-history-page

.purchase-history-page {
  @include sm {
    padding-right: 4vh;
  }
  @include md {
    padding-right: 3vh;
  }
}

//table cell
.css-1ex1afd-MuiTableCell-root {
  padding: 4vh 2vh !important;
  @include md {
    padding: 16px !important;
  }
}
.css-t752vm{
  background-color: #2b3d4f !important;
}
// purchase-status
.purchase-state-chip {
  font-family: $Gilroy-semi-bold !important;
  padding-left: 0.9vh !important;
  padding-right: 0.9vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .css-6od3lo-MuiChip-label {
    overflow: visible !important;
  }

  &.paid {
    background-color: #eaf4ed;
    color: #237455;
    @include sm {
      font-size: 0.75rem !important;
    }
    @include md {
      font-size: 0.8rem !important;
    }
  }

  &.canceled {
    background-color: #fcf4f2;
    color: #a4524b;
    @include sm {
      font-size: 0.75rem !important;
    }
    @include md {
      font-size: 0.8rem !important;
      padding: 1vh;
    }
  }

  &.refunded {
    background-color: #f2f4f7;
    color: #656c79;
    @include sm {
      font-size: 0.75rem !important;
    }
    @include md {
      font-size: 0.8rem !important;
    }
  }

  .icon {
    width: 18%;
    &__check {
      width: 24% !important;
      @include sm {
        width: 20% !important;
      }
      @include md {
        width: 18% !important;
      }
    }
    // #paid {
    //   width: 24% !important;
    // }
    @include sm {
      width: 16% !important;
    }
    @include md {
      width: 16% !important;
    }
  }
}

.purchase-history-table-container {
  position: relative;
  margin-top: 4vh !important;
  box-shadow: none !important;
  border-radius: 30px !important;
  overflow: hidden;
  padding: 3vh !important;
  width: 96% !important;
  @include sm {
    border-radius: 0 !important;
  }
  @include md {
    overflow-x: hidden !important;
  }
  &__rectangle-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 5%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    // background-color: $secondary-app-color !important ;
    @include sm {
      width: 218% !important;
      border-radius: 0 !important;
    }
    @include md {
      width: 218% !important;
    }
  }
}

.purchase-history-table {
  &__cell-title {
    font-family: $Gilroy-semi-bold !important;
    font-size: 1.1rem !important;
    &#course {
      width: 30% !important;
      @include md {
        width: 25% !important;
      }
    }
    &#progress {
      @include sm {
        display: none !important;
      }
    }
    @include sm {
      font-size: 0.9rem !important;
    }
    @include md {
      font-size: 0.95rem !important;
    }
  }
  &__cell-id {
    width: 10%;
    font-family: $Gilroy-semi-bold !important;
    font-size: 1rem !important;
    @include sm {
      font-size: 0.84rem !important;
    }
    @include md {
      font-size: 0.9rem !important;
    }
  }

  &__cell-status {
    width: 15%;
  }

  &__cell-course {
    width: 35%;
  }
  &__cell-progress {
    width: 25%;
    @include sm {
      display: none !important;
    }
  }

  &__cell-invoice {
    width: 15%;
  }
  &__cell-invoice-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &__cell-invoice-text {
    font-family: $default-font-family !important;
    color: $dark-grey !important;
    font-size: 0.9rem !important;
    @include sm {
      font-size: 0.75rem !important;
    }
    @include md {
      font-size: 0.8rem !important;
    }
  }
  &__cell-invoice-icon {
    width: 10% !important;
    margin-right: 0.7vh !important;
    @include sm {
      width: 12% !important;
    }
    @include md {
      width: 21% !important;
    }
  }
}

.progress-wrapper {
  display: flex;
  align-items: center;
  &__progress {
    height: 11px !important;
    border-radius: 15px !important;
    background-color: #f2f3f6 !important;
    margin-right: 1vh !important;
    @include sm {
      width: 100% !important;
      margin-right: 6vh !important;
    }
    @include md {
      width: 100% !important;
      margin-right: 6vh !important;
    }
    .css-5xe99f-MuiLinearProgress-bar1 {
      background-color: $secondary-app-color !important;
    }
    &-text {
      font-family: $default-font-family !important;
      font-size: 0.5rem;
      @include sm {
        margin-left: 1vh !important;
        font-size: 0.69rem !important;
      }
      @include md {
        margin-left: 1vh !important;
        font-size: 0.75rem !important;
      }
    }
  }
}
