.curriculum-card {
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 7vh 5vh;
  background-color: #fff !important;
  box-shadow: none;
  margin-top: 3vh !important;
  @include sm {
    padding: 7vh 3vh;
  }
  &__title {
    font-size: 1.3rem !important;
    margin-bottom: 1vh !important;
    font-family: $default-font-family !important;
    color: #000 !important;
  }

  &__timeline-wrapper {
    width: 100%;

    &--title {
      margin-left: 1vh !important;
      font-family: $Gilroy-semi-bold !important;
      font-size: 1.05rem !important;
      color: $secondary-app-color !important;
      @include sm {
        font-size: 0.95rem !important;
        // white-space: nowrap;
      }
      @include md {
        font-size: 0.9rem !important;
        // white-space: nowrap;
      }
    }
    &__accordian {
      padding: 1vh 1vh;
      width: 100%;
      border: 1px solid #a9a9a9 !important;
      border-radius: 20px !important;
      margin-top: 3vh !important;
      margin-bottom: 3vh !important;
      box-shadow: none !important;
      &--summary {
        &--details {
          padding: 1vh 2vh 1vh !important;
        }
      }
    }
    &__checklist-text {
    }
    &__heading {
      display: flex;
      align-items: center;
      justify-content: space-around;
      // margin-left: 15vh;

      @include sm {
        margin-left: 0vh;
        display: none;
      }
      @include md {
        margin-left: 4vh;
      }
      &--text {
        margin-right: 4vh !important;
        white-space: nowrap;
        font-size: 0.95rem !important;
        font-family: $Gilroy-light !important;
        color: #000 !important;
        &#lesson {
          font-size: 1rem !important;
        }
        @include md {
          margin-right: 0vh !important;
          font-size: 0.8rem !important;
        }
      }
      &--icon {
        margin-left: 5vh !important;
        width: 5%;
        margin-right: 1vh;
        @include md {
          width: 6% !important;
        }
        &#clock {
          width: 5% !important;
          @include md {
            width: 6% !important;
          }
        }
      }
    }
  }
  //timeline content
  &-timeline-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__text {
      font-family: $Gilroy-regular !important;
      font-size: 0.95rem !important;
      color: $secondary-app-color !important;
      @include sm {
        font-size: 0.8rem !important;
      }
      @include md {
        font-size: 0.85rem !important;
      }
    }
    &__duration {
      font-family: $Gilroy-light !important;
      font-size: 0.85rem !important;
      color: $secondary-app-color !important;
      @include sm {
        font-size: 0.7rem !important;
      }
      @include md {
        font-size: 0.75rem !important;
      }
    }
  }
}

.curriculum-card-timeline {
  padding: 0 !important;
  flex: 0;
  &__item {
    background-color: #f5faff !important;

    &:first-child {
      border-top-left-radius: 15px !important;
      border-top-right-radius: 15px !important;
    }
    &:last-child {
      border-bottom-left-radius: 15px !important;
      border-bottom-right-radius: 15px !important;
    }
    &--connector {
      opacity: 0.5 !important;
      width: 1px !important;
    }
    &--content {
      padding: 3vh 4vh !important;
    }
  }
}
