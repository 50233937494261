.profile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;

  @include sm {
    margin-top: -2.8rem !important; 
    margin:-1rem;
  }

  &__header-text {
    align-self: start;
    color: $secondary-app-color !important;
    font-size: 2.18rem !important;

    @include sm {
      font-size: 1.5rem !important;
      margin-left:-2rem ;
    }

    @include md {
      font-size: 1.8rem !important;
    }
  }
}



.profile-form {
  margin-top: 3vh !important;
  background-color: #fff;
  padding: 4vh;
  padding-left: 8vh;
  padding-right: 8vh;
  border-radius: 30px;
  &__avatar {
    padding: 1px;
  }
  &__input-label {
    color: $grey-700 !important;
    font-weight: 200 !important;
    font-size: 1rem !important;
    margin-bottom: 1vh !important;
    @include sm {
      font-size: 0.8rem !important;
    }
    @include md {
      font-size: 0.9rem !important;
    }
  }
  &__button {
    text-transform: none !important;
    margin-left: 3vh !important;
    font-family: $Gilroy-bold !important;
    padding: 0.7vh 2.5vh !important;
    font-size: 0.85rem !important;
    border-radius: 30px !important;
    color: #3b5269 !important;
    border-color: #2b3d4f !important;
    @include sm {
      font-size: 0.65rem !important;
    }
    @include md {
      font-size: 0.8rem !important;
    }
  }
}
