.course-overview {
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 7vh 5vh;
  background-color: #fff !important;
  box-shadow: none;
  margin-top: 2vh;
  &__title {
    margin-bottom: 4vh !important;
    font-size: 1.3rem !important;
    font-family: $default-font-family !important;
    color: #000 !important;
    @include sm {
      font-size: 1.3rem !important;
    }
    @include md {
      font-size: 1.3rem !important;
    }
  }
  &__description {
    font-size: 0.95rem !important;
    line-height: 25px !important;
    font-family: $Gilroy-light !important;
    color: #000 !important;
    @include sm {
      font-size: 0.925rem !important;
    }
    @include md {
      font-size: 0.9rem !important;
    }
  }
}
