.setting-page {
  margin-top: 4vh !important;
  padding-bottom: 8vh !important;
  @media (min-width: 1200px) {
    max-width: none !important;
  }
  @media (min-width: 600px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
