//help center page
.help-center-page {
  padding-top: 3vh !important;

  &__title {
    padding-top: 10vh !important;
    margin-bottom: 3vh !important;
    color: #000;
    font-size: 1.4rem !important;
    font-family: $default-font-family !important;
    @include sm {
      padding-top: 5vh !important;
      font-size: 1.2rem !important;
    }
    @include md {
      padding-top: 5vh !important;
      font-size: 1.3rem !important;
    }
  }
}

//help-center page two

.help-center-page-two {
  margin-left: 10vh !important;
  margin-right: 10vh !important;
  padding-top: 10vh;
  &__wrapper {
    display: flex;
    gap: 1.5rem;
    @include sm {
      display: inline;
    }
  }
  @include sm {
    margin-left: 0vh !important;
  }
  @include md {
    margin-left: 0vh !important;
  }
}

//help center page three
.help-center-page-three {
  padding-top: 10vh;
  margin-left: 10vh !important;
  @include sm {
    margin-left: 0vh !important;
  }
  @include md {
    margin-left: 0vh !important;
  }
}
