.DisciplineInSchoolCard {
  margin-top: 4vh !important;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 30px !important;
  padding: 3vh 7vh !important;
  box-shadow: none !important;
  background: linear-gradient(to right, #4b7298 0%, #39cccc 100%) !important;
  color: #fff !important;
  height: 90%;
  width: 91%;
  @include sm {
    display: flex;
    flex-direction: column-reverse;
    width: 86%;
    padding: 7vh 3vh 3vh 3vh !important;
  }
  @include md {
    width: 79%;
  }
  //image
  &__image {
    border-radius: 30px !important;
    width: 24%;
    text-align: end;
    box-shadow: none !important;
    margin-bottom: 6vh !important;
    @include sm {
      margin-bottom: 2vh !important;
      width: 83%;
    }
    @include md {
      width: 40%;
    }
  }
  &__wrapper {
    width: 30%;
    height: 85%;
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
  }
  //card footer section
  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 95% !important;
    height: 11%;
    padding: 1vh 7vh !important;
    background-color: #fff !important ;
    @include sm {
      width: 100% !important;
      padding: 1vh 1vh !important;
      height: 6%;
      border-radius: 0 !important;
    }
    @include md {
      padding: 1vh 2vh !important;
      width: 95% !important;
    }

    //content wrapper
    &-content {
      padding-left: 2vh;
      padding-right: 2vh;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include md {
        padding-right: 0vh;
      }
      &--details {
        display: flex;
        align-items: center;
        &-text {
          text-transform: capitalize !important;
          white-space: nowrap;
          color: $secondary-app-color !important;
          font-family: $Gilroy-semi-bold !important;
          font-size: 1.2rem !important;
          margin-right: 10vh !important;
          @include sm {
            margin-right: 3vh !important;
            font-size: 0.85rem !important;
          }
          @include md {
            margin-right: 6vh !important;
            font-size: 0.9rem !important;
            margin-right: 5vh !important;
          }
        }
        &-icon {
          width: 4.5%;
          margin-right: 1.6vh;
          @include sm {
            display: none !important;
            width: 11%;
            margin-right: 1.1vh;
          }
          @include md {
            width: 4.5%;
            margin-right: 1vh;
          }
        }
        &-time-remaining {
          text-transform: capitalize !important;
          white-space: nowrap;
          color: $secondary-app-color !important;
          font-family: $Gilroy-light !important;
          font-size: 0.95rem !important;
          @include sm {
            display: none !important;
            font-size: 0.95rem !important;
          }
          @include md {
            font-size: 0.85rem !important;
          }
        }
      }
      &--text-link {
        margin-top: 0.4vh !important;
        font-size: 1rem !important;
        color: $secondary-app-color !important;
        font-family: $Gilroy-semi-bold !important;
        @include sm {
          white-space: nowrap;
          font-size: 0.75rem !important;
        }
        @include md {
          white-space: nowrap;
          font-size: 0.97rem !important;
        }
      }
      &--chevron-icon {
        margin-left: 1vh !important;
        width: 17%;
        @include sm {
          width: 16% !important;
          margin-top: 0.4vh !important;
          margin-left: 0vh !important;
        }
        @include md {
          margin-left: 0vh !important;
        }
      }
    }
  }
  .DisciplineInSchoolCard-info-wrapper {
    &__title {
      text-transform: capitalize !important;
      font-size: 1.7rem !important;
      white-space: nowrap;
      color: #fff !important;
      font-family: $Gilroy-semi-bold !important;
      margin-bottom: 1rem !important;
      @include sm {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
        font-size: 1.1rem !important;
      }
      @include md {
        font-size: 1.3rem !important;
      }
    }

    &__details {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      @include sm {
        margin-bottom: 2rem;
        margin-top: 0.5vh !important;
        gap: 1rem;
      }
      @include md {
        margin-top: 0.6vh !important;
        gap: 1rem;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-icon {
        width: 10%;
        margin-right: 0.25rem;
      }
      #star {
        width: 36%;
        @include sm {
          margin-right: 0.4rem;
          width: 35% !important;
        }
        @include md {
          width: 33% !important;
          margin-right: 0.4rem;
        }
      }
      #barchart {
        width: 17%;
        @include sm {
          margin-right: 0.4rem;
          width: 17% !important;
        }
        @include md {
          width: 14% !important;
          margin-right: 0.4rem;
        }
      }
      #clock {
        width: 28%;
        @include sm {
          margin-right: 0.4rem;
          width: 28% !important;
        }
        @include md {
          width: 23% !important;
          margin-right: 0.4rem;
        }
      }

      &-text {
        text-transform: capitalize !important;
        white-space: nowrap !important;
        margin-left: 0.1vh !important;
        font-family: $Gilroy-light !important;
        font-size: 0.9rem !important;
        color: #ededed;
        @include sm {
          margin-right: 0.4rem !important;
          margin-left: 0vh !important;
          font-size: 0.85rem !important;
          white-space: nowrap;
        }
        @include md {
          margin-right: 0.55rem !important;
          margin-left: 0vh !important;
          font-size: 0.8rem !important;
          white-space: nowrap;
        }
      }
    }
    //in-progress button
    &__in-progress-button {
      text-transform: capitalize !important;
      margin-top: 2vh !important;
      border-color: #2b3d4f !important;
      background-color: #1d2732 !important;
      color: #efefef !important;
      font-family: $Gilroy-bold !important;
      border-radius: 30px;
      font-size: 0.9rem !important;
      padding: 0.85vh 6vh;
      box-shadow: none !important;
      margin-bottom: 10vh !important;
      @include sm {
        margin-bottom: 7vh !important;
        padding: 0.9vh 6vh;
        font-size: 0.8rem !important;
        margin-left: 3vh;
      }
      @include md {
        margin-top: 2vh !important;
        margin-bottom: 6vh !important;
        padding: 0.9vh 6vh;
        font-size: 0.8rem !important;
        margin-left: 3vh;
      }
    }
  }
}
