.course-activity-stat-wrapper {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
  width: 94%;
  height: 100%;
  padding: 3vh 4vh 3vh;
  box-shadow: none;
  background-color: #fff;
  border-radius: 30px;
  margin-top: 4vh !important;
  @include md {
    width: 83%;
  }
  &__title {
    font-family: $Gilroy-semi-bold !important;
    font-size: 1.3rem !important;
    color: #000 !important;
  }
  &__caption {
    font-family: $Gilroy-light !important;
    font-size: 1rem !important;
    color: #000 !important;
  }
}
