.toggle-button-group {
  // border-radius: 40px !important;
  font-family: $Gilroy-regular !important;
  text-transform: none !important;
  margin-top: 2vh !important;
  margin-bottom: 2vh !important;
  .MuiToggleButton-root {
    &.active {
      padding: 1.5vh 2.7vh;
      background-color: $secondary-app-color !important;
      color: white;
      @include sm {
        font-size: 0.7rem !important;
      }
      @include md {
        font-size: 0.75rem !important;
      }
    }

    &.normal {
      padding: 1.5vh 2.7vh;
      background-color: white;
      color: #2a2f3a;
      border-color: $dark-grey !important;
      @include sm {
        font-size: 0.7rem !important;
      }
      @include md {
        font-size: 0.75rem !important;
      }
    }
  }
}

.css-ueukts-MuiButtonBase-root-MuiToggleButton-root {
  font-family: $Gilroy-regular !important;
  text-transform: none !important;
}

//last button class
.css-bghv3y-MuiToggleButtonGroup-root .MuiToggleButtonGroup-lastButton {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

//first button class
.css-bghv3y-MuiToggleButtonGroup-root .MuiToggleButtonGroup-firstButton {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.MuiToggleButton-root.normal{
text-transform: none !important;
}
.MuiToggleButton-root.active{
text-transform: none !important;
}