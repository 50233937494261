.course-forum-card {
  width: 30%;
  height: 70%;
  padding: 4vh 3vh;
  background-color: #ffeed2 !important;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  @include sm {
    width: 100%;
    height: 100%;
    padding: 6vh 3vh !important;
  }
  @include md {
    width: 30%;
    height: 63%;
    padding: 6vh 4vh !important;
  }
  &#chat-room {
    background-color: #c7ffff !important;
  }
  &--num {
    font-family: $Gilroy-light !important;
    color: #3e3e3e !important;
    font-size: 1rem !important;
    margin-bottom: 4vh !important;
    @include sm {
      font-size: 1.3rem !important;
    }
  }
  &--annotation-image {
    width: 23%;
    margin-bottom: 2vh !important;
    @include sm {
      width: 20%;
      margin-top: 1vh !important;
      margin-bottom: 2.5vh !important;
    }
    @include md {
      width: 25%;
    }
  }
  &--course-forum {
    font-family: $Gilroy-semi-bold !important;
    color: #2b3d4f !important;
    font-size: 1rem !important;
    margin-bottom: 1vh !important;
    @include sm {
      font-size: 1.3rem !important;
    }
    @include md {
      font-size: 1.25rem !important;
    }
    &--description {
      font-family: $Gilroy-light !important;
      color: #3b5269 !important;
      font-size: 0.8rem !important;
      margin-bottom: 2vh !important;
      padding-right: 3vh !important;
      @include sm {
        font-size: 0.85rem !important;
      }
      @include md {
        font-size: 0.75rem !important;
      }
    }
  }
  &--visit-forum {
    display: flex;
    align-items: center;
    &-text {
      font-family: $Gilroy-regular !important;
      color: $secondary-app-color !important;
      font-size: 0.9rem !important;
      margin-right: 1vh !important;
      @include sm {
        margin-top: 1vh !important;
        font-size: 1.05rem !important;
      }
      @include md {
        font-size: 0.9rem !important;
        margin-bottom: 1vh !important;
      }
    }
    &-icon {
      transform: rotate(90deg);
      // width: 19%;
    }
  }
}
