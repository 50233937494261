//MCQ-page
.MCQ-page {
  height: 100% !important;
  width: 100% !important;
}

.MCQ_wrapper {
  max-height: 40vh;
  border-top: 170px solid #e4efff !important;
  background-color: #fff !important;
  border-radius: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4vh 6vh !important;
  @include sm {
    padding: 4vh 3vh !important;
    border-top: 140px solid #e4efff !important;
  }
  @include md {
    margin-top: 4vh !important;
    margin-bottom: 12vh !important;
    padding: 3vh 6vh !important;
  }
  &__Question-box {
    z-index: 7;
    position: relative;
    top: -11vh;
    padding: 4vh 20vh;
    background-color: #fff;
    margin-top: 5vh !important;
    box-shadow: $smooth-shadow;
    border-radius: 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include sm {
      padding: 6vh 3vh;
      width: 77% !important;
      margin-top: 0vh !important;
    }
    @include md {
      padding: 5vh 3vh;
      margin-top: 7vh !important;
      width: 75%;
    }
    &--number {
      position: relative;
      top: -7vh;
      border-radius: 30px;
      background-color: $secondary-app-color !important;
      &-text {
        padding: 1.2vh 5.5vh !important;
        font-family: $Gilroy-bold !important;
        font-size: 1.05rem !important;
        color: #fff !important;
        @include sm {
          padding: 1.2vh 6.5vh !important;
          font-size: 1rem !important;
        }
      }
    }
    &--question {
      font-family: $default-font-family !important;
      font-size: 1.2rem !important;
      color: $secondary-app-color !important;
      @include sm {
        font-size: 1rem !important;
      }
      @include md {
        font-size: 1.2rem !important;
      }
    }
  }
  &__options-wrapper {
    margin-top: 2vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    @include sm {
      margin-top: 12vh !important;
    }
    &--option-button {
      white-space: nowrap !important;
      border: 1px solid rgba(43, 61, 79, 0.26) !important;
      border-radius: 15px !important;
      padding: 1vh 18vh !important;
      font-family: $default-font-family !important;
      font-size: 1.1rem !important;
      color: $secondary-app-color !important;
      @include sm {
        font-size: 0.9rem !important;
      }
      @include md {
        padding: 1vh 11.5vh !important;
      }
      &--active {
        white-space: nowrap !important;
        border-radius: 15px !important;
        padding: 1vh 18vh !important;
        font-family: $default-font-family !important;
        font-size: 1.1rem !important;
        color: $secondary-app-color !important;
        background-color: #eaffff !important;
        border: 2px solid $primary-color !important;
        text-transform: capitalize !important;
        @include sm {
          font-size: 0.9rem !important;
        }
        @include md {
          padding: 1vh 11.5vh !important;
        }
      }
    }
  }
  &__answer-wrapper {
    position: relative;
    top: -8vh;
    width: 60%;
    @include sm {
      width: 87%;
    }
    @include md {
      width: 100%;
    }
  }
  &-navigate {
    margin-top: 0vh !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff !important;
    padding: 5vh 0.5vh !important;
    @include sm {
      padding: 0vh 0vh !important;
    }
    @include md {
      padding: 0vh 0vh !important;
    }
    &#no-bg {
      background-color: transparent !important;
      @include sm {
        margin-left: 0vh !important;
        margin-bottom: 3vh !important;
      }
      @include md {
        margin-left: 0vh !important;
      }
    }

    &__button {
      font-size: 0.85rem !important;
      color: #3b5269 !important;
      padding: 0.7vh 8vh !important;
      font-family: $Gilroy-bold !important;
      border-radius: 30px !important;
      border-color: #2b3d4f !important;
      text-transform: capitalize !important;
      position: relative;
      @include sm {
        padding: 0.5vh 3vh !important;
        font-size: 0.7rem !important;
      }
      @include md {
        padding: 0.5vh 6vh !important;
        font-size: 0.7rem !important;
        margin-bottom: 3vh !important;
      }
      &#next {
        margin-right: 2vh !important;
        @include sm {
          margin-right: 0vh !important;
        }
      }
      &#white-bg {
        background-color: #fff !important;
      }

      // &::after {
      //   content: "";
      //   position: absolute;
      //   left: 0;
      //   right: 0;
      //   bottom: -24px;
      //   height: 2px;
      //   background-color: #3b5269;
      //   transition: opacity 0.3s;
      //   opacity: 0;
      // }

      &.active::after {
        opacity: 1;
      }
    }
    &__title {
      font-size: 1.3rem !important;
      color: #242222 !important;
      font-family: $default-font-family !important;
      @include sm {
        font-size: 1rem !important;
      }
      @include md {
        font-size: 0.95rem !important;
      }
    }
    &__progress {
      width: 105%;
      height: 7px !important;
      border-radius: 0px !important;
      background-color: #ffffff !important;
      @include sm {
        width: 105% !important;
        margin-right: 6vh !important;
        border-radius: 0px !important;
      }
      @include md {
        width: 106% !important;
        margin-right: 0vh !important;
      }
      .css-5xe99f-MuiLinearProgress-bar1 {
        background-color: $secondary-app-color !important;
      }
    }
  }
  &__progress {
    z-index: 99 !important;
    position: relative !important;
    bottom: 8vh;
    height: 20px !important;
    width: 107%;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 30px;
    background-color: $secondary-app-color !important;
    color: $primary-color !important;
    @include sm {
      bottom: -37px;
      width: 90%;
      height: 14px !important;
    }
    @include md {
      bottom: 205px;
      height: 24px !important;
      width: 93%;
    }
    .css-5xe99f-MuiLinearProgress-bar1 {
      background-color: $primary-color !important;
    }
  }
}
