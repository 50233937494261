.engage-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 91%;
  height: 90%;
  //   margin-left: auto;
  //   margin-right: auto;
  border-radius: 30px !important;
  padding: 3vh 7vh !important;
  box-shadow: none !important;
  background-color: #fff !important;
  margin-top: 2vh !important;

  @include sm {
    padding: 3vh 5vh !important;
    align-items: center;
    justify-content: space-between;
    margin-top: 5vh !important;
    flex-direction: column !important;
    width: 78%;
    height: 100%;
  }
  @include md {
    padding: 3vh 3vh !important;
    align-items: center;
    justify-content: space-around;
    margin-top: 5vh !important;
    flex-direction: column !important;
    width: 91%;
    height: 100%;
  }
  &__image-wrapper {
    &--image {
      margin-left: 8vh !important;
      padding: 2vh 1vh !important;
      width: 70%;
      @include sm {
        width: 61%;
        margin-left: 7vh !important;
      }
      @include md {
        width: 65%;
      }
    }
    &--text {
      font-weight: 600 !important;
      margin-left: 8vh !important;
      font-size: 1.8rem !important;
      font-family: Gilroy-semi-bold !important;
      color: $secondary-app-color !important;
      padding: 1vh 1vh !important;
      @include sm {
        text-align: center;
        font-size: 1.35rem !important;
        margin-left: 4vh !important;
      }
      @include md {
        font-size: 1.8rem !important;
        margin-left: 5vh !important;
      }
    }
  }
  &__card-holder {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 6rem;
    @include sm {
      flex-direction: column !important;
      gap: 3rem;
    }
    @include md {
      justify-content: space-around !important;
    }
  }
}
