.html-login-card {
  width: 70%;
  min-height: 100px;
  padding: 3vh !important;
  margin-top: 2vh !important;
  border-radius: 30px !important;
  background-color: #fff !important;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  // justify-content: space-between;
  @include sm {
    width: 89%;
  }
  @include md {
    width: 90%;
  }
  &__title {
    font-size: 1rem !important;
    color: #000 !important;
    font-family: $Gilroy-semi-bold !important;
    @include md {
      font-size: 1.1rem !important;
    }
  }
  &__para {
    margin-top: 1vh !important;
    font-size: 0.8rem !important;
    line-height: 1.5rem;
    color: #000 !important;
    font-family: $Gilroy-light !important;
    word-break: normal !important;  
    overflow-wrap: break-word !important;  // Handles long unbreakable words like URLs

    white-space: normal !important; 

    p {
      text-align: unset;  // Ensure no text alignment changes in paragraph
      word-break: normal !important;  // Avoid mid-word breaks in paragraphs
      overflow-wrap: break-word !important;  // Handle long words gracefully
      white-space: normal !important;  // Allow normal text wrapping in paragraphs
    }
    @include md {
      font-size: 0.9rem !important;
    }
  }
}
